import { AccessSessionClipStudents, VideoMode } from './constants';
import { userFromParticipant } from './utils';

const participantMessageOptions = () => ({
  label: 'Custom reactions',
  className: 'sub-menu-item',
  itemsContainerWidth: 200,
  items: [
    {
      type: 'message.prompt',
      label: `I can't hear you 😥`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `I can't see you 😣`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `I can't hear or see you 🤔`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `I can hear you 😀`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `I can see you`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `I can see and hear you`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `Can you hear me ?`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `Can you see me ?`,
      className: 'sub-menu-item'
    }
  ],
  emojis: [
    {
      type: 'message.prompt',
      label: `🙂`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `❤️`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `👍`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `👎`,
      className: 'sub-menu-item'
    },
    {
      type: 'message.prompt',
      label: `👋`,
      className: 'sub-menu-item'
    }
  ]
});

const participantActionOptions = (localParticipantInfo, participant, metadata) => {
  const actions = {
    label: 'Actions',
    className: 'sub-menu-item',
    itemsContainerWidth: 200,
    accessAdmin: false,
    items: []
  };

  const isMediaClipAccessible = AccessSessionClipStudents.includes(
    userFromParticipant(participant)
  );

  // if (localParticipantInfo.isAdmin && metadata.role === 'student' ) {
  //     actions.items.push(
  //         {
  //             type: 'actions.Pin_in_view',
  //             label: `Pin in view`,
  //             className: 'sub-menu-item',
  //         },
  //         {
  //             type: 'actions.spotlight',
  //             label: `Spotlight`,
  //             className: 'sub-menu-item',
  //         },
  //         {
  //             type: 'actions.ban',
  //             label: `Ban / Remove Participant`,
  //             className: 'sub-menu-item',
  //         }
  //     );

  //     if (participant.isCameraEnabled) {
  //         actions.items.push({
  //             type: 'actions.screenshot',
  //             label: `Take Screenshot`,
  //             className: 'sub-menu-item',
  //         });
  //     }

  //     if (participant.isMicrophoneEnabled) {
  //         actions.items.push({
  //             type: 'actions.mute',
  //             label: `Mute Student`,
  //             className: 'sub-menu-item',
  //         });
  //     } else {
  //         actions.items.push({
  //             type: 'message.prompt',
  //             label: `Ask to unmute`,
  //             className: 'sub-menu-item',
  //         });
  //     }

  //     let isVideoModePublic = metadata?.vid_mode === VideoMode.Public;

  //     if (isVideoModePublic) {
  //         actions.items.push({
  //             type: 'actions.video_to_teacher',
  //             label: `Video is visible only to teacher`,
  //             className: 'sub-menu-item',
  //         });
  //     }
  // }

  actions.items.push(
    {
      type: 'actions.Pin_in_view',
      label: `Pin in view`,
      className: 'sub-menu-item'
    },
    {
      type: 'actions.spotlight',
      label: `Spotlight`,
      className: 'sub-menu-item'
    }
  );

  if (localParticipantInfo.isAdmin && metadata.role === 'student') {
    if (participant.isMicrophoneEnabled) {
      actions.items.push({
        type: 'actions.mute',
        label: `Mute Student`,
        className: 'sub-menu-item'
      });
    } else {
      actions.items.push({
        type: 'message.prompt',
        label: `Ask to unmute`,
        className: 'sub-menu-item'
      });
    }
    actions.items.push({
      type: 'actions.ban',
      label: `Remove Student`,
      className: 'sub-menu-item'
    });
  }

  if (participant.isCameraEnabled && isMediaClipAccessible) {
    actions.items.push({
      type: 'actions.screenshot',
      label: `Take Screenshot`,
      className: 'sub-menu-item'
    });
  }
  if (participant.isCameraEnabled && isMediaClipAccessible) {
    actions.items.push({
      type: 'actions.clipStart',
      label: `Start clip`,
      className: 'sub-menu-item'
    });
  }

  if (participant.isCameraEnabled && isMediaClipAccessible) {
    actions.items.push({
      type: 'actions.clipStop',
      label: `Stop clip`,
      className: 'sub-menu-item'
    });
  }

  const isVideoModePublic = metadata?.vid_mode === VideoMode.Public;

  if (isVideoModePublic) {
    actions.items.push({
      type: 'actions.video_to_teacher',
      label: `Video is visible only to teacher`,
      className: 'sub-menu-item'
    });
  }

  // if (localParticipantInfo.isAdmin) {
  //     actions.items.push({
  //         type: 'actions.directed_audio',
  //         label: `Toggle Directed Audio`,
  //         className: 'sub-menu-item',
  //     });
  // }

  return actions;
};

const selfActionOptions = (localParticipantInfo, participant, metadata) => {
  const actions = {
    label: 'Actions',
    className: 'sub-menu-item',
    itemsContainerWidth: 200,
    accessAdmin: false,
    items: []
  };

  if (metadata.role === 'student') {
    const onlyTeacherCanSeeMe = metadata?.vid_mode === VideoMode.Teacher;

    if (onlyTeacherCanSeeMe) {
      actions.items.push({
        type: 'actions.video_to_all',
        label: `All can see me`,
        className: 'sub-menu-item'
      });
    } else {
      actions.items.push({
        type: 'actions.video_to_teacher',
        label: `Only Teacher can see me`,
        className: 'sub-menu-item'
      });
    }
  }

  return actions;
};

export const buildMenuOptions = (localParticipantInfo, participant, metadata) => {
  let menuOptions = [];

  // if (participant.isLocal) {
  //     menuOptions = [selfActionOptions(localParticipantInfo, participant, metadata)].filter(
  //         (x) => x.items.length > 0
  //     );
  // } else {
  //     menuOptions = [
  //         participantActionOptions(localParticipantInfo, participant, metadata),
  //         participantMessageOptions(),
  //     ].filter((x) => x.items.length > 0);
  // }

  if (participant.isLocal) {
    menuOptions = [selfActionOptions(localParticipantInfo, participant, metadata)].filter(
      (x) => x.items.length > 0
    );
  }

  menuOptions = [
    participantActionOptions(localParticipantInfo, participant, metadata),
    participantMessageOptions()
  ].filter((x) => x.items.length > 0);

  return menuOptions.map((x) => {
    if (x.items) {
      x.items = x.items.map((y) => {
        y.value = y.label;
        y.sid = participant.sid;
        y.identity = participant.identity;

        return y;
      });
    }
    if (x.emojis) {
      x.emojis = x.emojis.map((y) => {
        y.value = y.label;
        y.sid = participant.sid;
        y.identity = participant.identity;

        return y;
      });
    }

    return x;
  });
};

export const CUSTOM_MODAL = {
  show: false,
  title: '',
  body: '.',
  onClose: '',
  onConfirm: '',
  confirmText: '',
  cancelText: '',
  showErrorSvg: false,
  endSessionForAll: '',
};
