import {
  DataPacket_Kind,
  RoomEvent,
  Room,
  MediaDeviceFailure,
  ConnectionState,
  ConnectionQuality,
  Track
} from 'livekit-client';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillSpeakerFill,
  BsFillFileMusicFill,
  BsFillFileEarmarkMusicFill,
  BsPersonVideo,
  BsPersonVideo2
} from 'react-icons/bs';

import {
  MdDevices,
  MdImportantDevices,
  MdCallEnd,
  MdScreenShare,
  MdStopScreenShare,
  MdViewQuilt
} from 'react-icons/md';

import {
  AiFillSignal,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
  AiOutlineMenu
} from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';
import './Livekit.css';
// import 'react-toastify/dist/ReactToastify.css';
import { CiSquareMore } from 'react-icons/ci';
import { TiTick } from 'react-icons/ti';
import { deviceDetect, isFirefox, isSafari, isIOS, isTablet } from 'react-device-detect';
import LiveKitErrorModal from './modals/LivekitErrorModal';
import SessionEndedModal from './modals/SessionEndedModal';
import { TbPinned, TbPinnedOff } from 'react-icons/tb';
import urls from '../../../urls';
import { HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { FaUsers, FaUsersSlash } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import SettingsModal from './modals/SettingsModal';
import { VideoMode, RecordingStatus, DeviceMode, AccessSessionClipStudents } from './constants';
import { CUSTOM_MODAL, buildMenuOptions } from './menu';
import { Dropdown } from 'react-nested-dropdown';
import 'react-nested-dropdown/dist/styles.css';
import { BiLoader } from 'react-icons/bi';
import { FcGallery } from 'react-icons/fc';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import MediaViewerPlayer from './modals/MediaViewerPlayer';
// import LivekitInfoModal from './modals/LivekitInfoModal';
import ClosedCaptions from './ClosedCaptions';
import Loader from '../../new_teacher_wesite/components/loader';
import { callJsonPostApi, userFromParticipant } from './utils';
import {
  GalleryIcon,
  MoreThreeDots,
  NetworkSignal,
  NetworkSignalStable,
  OrangeTick,
  Participants,
  PinnedIcon,
  UnMuteCall,
  UnPinnedIcon,
  VideoLogo,
  VideoRecordingStart,
  VideoRecordingStop,
  VideocallOptions,
  Views
} from './livekit_assets/svg';
import {
  AdioMuteIcon,
  CameraOffIcon,
  CameraOnIcon,
  EmojiIcon,
  EndCallIcon,
  MoreMenuIcon,
  MuteAllIcon,
  MuteIcon,
  ScreenShareIcon,
  ThreeDots,
  UnmuteIcon
} from '../../../../svg';
import NetworkConnectionStatus from './modals/NetworkConnectionStatus/index';
import ParticipantsListPannel from './modals/ParticipantsListPannel';
import RecordingPopUp from './modals/RecordingPopUp';
import ReactionModal from './modals/ReactionModal';
import VideoDropDownMenu from './modals/VideoDropDownMenu';
import SessionImagesDrawer from './modals/SessionImagesDrawer';
import NotificationBadge from './modals/NotificationBadge';

function LivekitVideoContainer({ meetingArgs, room }) {
  const ViewTypeGallery = 'Grid view';
  const ViewTypeSpeaker = 'Speaker View';

  const VIEWS = [ViewTypeGallery, ViewTypeSpeaker];

  const ERROR_MODAL = {
    show: false,
    errorCode: null
  };

  const reactionRef = useRef(null);
  const networkStatusRef = useRef(null);
  const viewRef = useRef(null);
  const headerMoreRef = useRef(null);

  const [connectingToRoom, setConnectingToRoom] = useState(false);
  const [isConnectedToRoom, setIsConnectedToRoom] = useState(false);
  const [customModal, setCustomModal] = useState({ ...CUSTOM_MODAL });
  const [showParticipantSubmenu, setShowParticipantSubmenu] = useState({
    show: false,
    sid: null
  });
  const [closedCaptions, setClosedCaptions] = useState('');
  const [micOptions, setMicOptions] = useState({ media: [], show: false });
  const [camOptions, setCamOptions] = useState({ media: [], show: false });
  const [activeDevices, setActiveDevices] = useState({
    audio: null,
    video: null,
    audioOut: null
  });
  const [outputOptions, setOutputOptions] = useState({
    media: [],
    show: false
  });
  console.log(outputOptions, 'outputOptions');
  const [livekitInfoModal, setLivekitInfoModal] = useState({ show: false });
  const [errorModal] = useState({
    ...ERROR_MODAL
  });
  const [mediaViewerModal, setMediaViewerModal] = useState({ show: false, medias: [] });

  // views

  // speaker, pinned, spotlight, screen-share -> true, else false for gallery view
  const [showViews, setShowViews] = useState(false);
  const [selectedView, _setSelectedView] = useState('');
  const selectedViewRef = useRef(ViewTypeGallery);
  const setSelectedView = (item) => {
    selectedViewRef.current = item;
    _setSelectedView(item);
  };

  const [isFullScreenActive, setIsFullScreenActive] = useState(false);

  const [isMeetingLocked, setIsMeetingLocked] = useState(false);
  const [showParticipantPanel, setShowParticipantPanel] = useState(false);
  const [showGalleryPanel, setShowGalleryPanel] = useState(false);
  const [settingsModal, setSettingsModal] = useState({ show: false });
  const [renderState, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  // screenshot images in the session
  const [mediasInSession, setMediasInSession] = useState(() => meetingArgs?.medias || []);

  const [showRecStop, setShowRecStop] = useState(false);
  const [recStopInProgress, setRecStopInProgress] = useState(
    () => meetingArgs.livekit.config.recording === RecordingStatus.WillStart
  );

  const [recordStatus, setRecordStatus] = useState(meetingArgs.livekit.config.recording);

  const [isAllMuted, setAllMuted] = useState(false);
  const [directedAudio, setDirectedAudio] = useState({
    identity: '',
    enableMicOnStop: false,
    trackSid: '',
    enabled: false,
    name: ''
  });
  const [headerShowMore, setHeaderShowMore] = useState(false);
  const [isVideoPinned, setIsVideoPinned] = useState(false);
  const [personSpeaking, setPersonSpeaking] = useState('');
  const [defaultScreen, setDefaultScreen] = useState('');
  const [isVideoDropDown, setIsVideoDropDown] = useState(false);
  const [isReactionOpen, setIsReactionOpen] = useState(false);
  const [currentInnerWidth, setCurrentInnerWidth] = useState(window.innerWidth);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [numberOfParticipantsJoined, setNumberOfParticipantsJoined] = useState(0);

  const [isClipRecOngoing, setIsClipRecOngoing] = useState(false);
  const onClipStopTimerRef = useRef(null);
  const [targetParticipants, setTargetParticipants] = useState([]);

  //   {
  //     role, avatar, is_admin, vid_mode
  //   }
  const [localParticipantInfo, _] = useState(() => {
    function parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          ?.atob(base64)
          ?.split('')
          ?.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          ?.join('')
      );

      return JSON.parse(jsonPayload);
    }

    const info = JSON.parse(parseJwt(meetingArgs.livekit.join_info.token).metadata);

    info.isAdmin = info.role === 'teacher' || info.role === 'ops';

    return info;
  });

  const toastId = useRef(null);

  //  init encoder and decoder for the data channel
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const maxGridColumns = 4;
  const maxGridRows = 4;
  const maxGridElements = maxGridColumns * maxGridRows;

  const liveKitScreenHeight = isIOS || isSafari || (isIOS && isTablet);

  const DummyTrackSid = 'dummy';

  const cleanState = () => {
    console.log('ALERT!!! cleaning state');
    return {
      all: [],
      grid: [],
      list: [],
      columns: 1,
      rows: 1
    };
  };

  // participant data with UI state
  const [participantState, _setParticipantState] = useState(() => cleanState());
  const participantStateRef = useRef(participantState);

  const setParticipantState = (newState) => {
    participantStateRef.current = newState;
    _setParticipantState(newState);
  };

  const [deviceMode, _setDeviceMode] = useState(() => DeviceMode.Main);
  const deviceModeRef = useRef(deviceMode);

  const setDeviceMode = (nextMode) => {
    deviceModeRef.current = nextMode;
    _setDeviceMode(nextMode);
  };

  const [audioStarted, setAudioStarted] = useState(false);
  const noDeviceFound =
    camOptions.media.length === 0 &&
    outputOptions.media.length === 0 &&
    micOptions.media.length === 0;

  const startAudioOnTap = () => {
    if (!audioStarted) {
      room.startAudio();
      setAudioStarted(true);
    }
  };

  //   function that connects to the room
  const connectToRoom = async () => {
    setConnectingToRoom(true);
    await room.connect(meetingArgs.livekit.join_info.host, meetingArgs.livekit.join_info.token, {
      autoSubscribe: true
    });
    // if (isSafari) {
    //     setCustomModal({
    //         show: true,
    //         title: 'Audio Play Permission',
    //         body: 'Click to start audio',
    //         onCancel: () => {
    //             startAudioOnTap();
    //             setCustomModal({ ...CUSTOM_MODAL });
    //         },
    //         onConfirm: () => {
    //             startAudioOnTap();
    //             setCustomModal({ ...CUSTOM_MODAL });
    //         },
    //         confirmText: 'Play',
    //         cancelText: '',
    //         showErrorSvg: false,
    //     });
    // }

    setConnectingToRoom(false);
    setIsConnectedToRoom(true);
    setRecStopInProgress(false);
    setRecordStatus(room.isRecording ? RecordingStatus.OnGoing : RecordingStatus.WillStart);

    // do post loading other participants for getting information about users other device
    handleParticipantConnected(room.localParticipant);

    // load participants & tracks
    Array.from(room.remoteParticipants.values()).forEach((participant) => {
      handleParticipantConnected(participant);

      Array.from(participant.trackPublications.values()).forEach((publication) => {
        if (publication.isSubscribed) {
          handleTrackSubscribed(publication.track, publication, participant);
        }
      });
    });

    handleStartVideoBtn(null, true);

    if (deviceMode === DeviceMode.Main) {
      handleStartAudioBtn(null, true);
    }
  };

  //   handler for start video
  const handleStartVideoBtn = async (e, forceStart) => {
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();

    if (e) {
      startAudioOnTap();
    }

    if (!!forceStart && !room.localParticipant.isCameraEnabled) {
      return;
    }

    try {
      // enable the video track
      await room.localParticipant.setCameraEnabled(!room.localParticipant.isCameraEnabled);
    } catch (error) {
      console.error(error);
      toast.warn('Please provide camera permission and validate device connectivity', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      });
    }
    // setCameraTestInProgress(true);
    forceUpdate();
  };

  //   handler for audio start
  const handleStartAudioBtn = async (e, forceStart) => {
    e?.stopPropagation();
    e?.nativeEvent?.stopImmediatePropagation();

    if (e) {
      startAudioOnTap();
    }

    if (!!forceStart && !room.localParticipant.isMicrophoneEnabled) {
      return;
    }

    try {
      // enable the audio track
      await room.localParticipant.setMicrophoneEnabled(!room.localParticipant.isMicrophoneEnabled);
      // update the mic state
    } catch (error) {
      console.error('handleStartAudioBtn', error);
      toast.warn('Please provide audio permission and validate device connectivity', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      });
    }

    forceUpdate();
  };

  const handleTogglePaticipantPanel = () => {
    if (showGalleryPanel) setShowGalleryPanel(false);
    // update the participant panel state
    setShowParticipantPanel(!showParticipantPanel);
    handleClickOutside();
  };

  const handleToggleGalleryPanel = () => {
    if (showParticipantPanel) setShowParticipantPanel(false);
    setShowGalleryPanel((val) => !val);
    handleClickOutside();
  };

  //   handler to end the session
  const handleEndCall = () => {
    // trigger info modal
    setCustomModal({
      show: true,
      title: 'Leave Session',
      body: 'Are you sure you want to leave this session?',
      onCancel: () => setCustomModal({ ...CUSTOM_MODAL }),
      onConfirm: () => {
        setCustomModal({ ...CUSTOM_MODAL });
        endCall();
      },
      endSessionForAll: () => {
        setCustomModal({ ...CUSTOM_MODAL });
        endSessionForAll();
      },
      confirmText: 'Leave Session',
      cancelText: 'Cancel'
    });
  };

  const endCall = async () => {
    try {
      if (room.state !== ConnectionState.Disconnected) {
        await room.disconnect();
        window.close();
      }
    } catch (error) {
      console.error(error);
    }

    try {
        connectToRoom();
    //   setCustomModal({
    //     show: true,
    //     title: 'Session Left',
    //     body: 'The session has been left',
    //     onCancel: () => window.close(),
    //     onConfirm: () => {
    //       // mobile app
    //       if (window.ReactNativeWebView !== undefined) {
    //         window.ReactNativeWebView.postMessage(
    //           JSON.stringify({
    //             status: 'success'
    //           })
    //         );
    //       } else {
    //         window.location.replace('/');
    //       }
    //     },
    //     confirmText: 'Go to dashboard',
    //     cancelText: '',
    //     showErrorSvg: false
    //   });
    } catch (error) {
      console.error(error);
    }
  };

  //   callback when local track is published
  const handleLocalTrackPublished = (publication, participant) => {
    console.log('handleLocalTrackPublished: ', publication, participant);

    if (publication.track.kind === Track.Kind.Audio) {
      // ignore local audio to avoid loop
      return;
    }

    handleTrackSubscribed(publication.track, publication, participant);
  };

  // *callback fired when a local track is unpublished
  const handleLocalTrackUnpublished = (publication, participant) => {
    console.log('handleLocalTrackUnpublished: ', publication, participant);

    if (publication.track.kind === Track.Kind.Audio) {
      // ignore local audio to avoid loop
      return;
    }

    handleTrackUnsubscribed(publication.track, publication, participant);
  };

  const getParticipant = (item) =>
    item.isSelf ? room.localParticipant : room.remoteParticipants.get(item.identity);

  const processUpdatedParticipantState = (incomingState) => {
    const participantGrid = [];
    const participantList = [];

    const isNonePinned =
      incomingState.all.filter((item) => {
        if (item.isVideoPinned) {
          return true;
        }

        return item.isScreenShareEnabled && item.isScreenSharePinned;
      }).length === 0;

    const getByValue = (map, searchValue) => {
      for (const [key, value] of map.entries()) {
        if (value === searchValue) return key;
      }

      return '';
    };

    for (let x = 0; x < incomingState.all.length; x++) {
      const item = incomingState.all[x];

      // always add a video item, to show remote participant
      const addVideoToGrid =
        (isNonePinned || item.isVideoPinned) && maxGridElements > participantGrid.length - 1;

      const videoTrackSid = getByValue(item.videoTracks, Track.Source.Camera) || DummyTrackSid;
      const videoItem = {
        identity: item.identity,
        sid: item.sid,
        isPinned: item.isVideoPinned,
        isSelf: item.isSelf,
        trackSid: videoTrackSid,
        isScreenShare: false,
        isSpotlight: item.isSpotlight,
      };

      if (addVideoToGrid) {
        participantGrid.push(videoItem);
      } else {
        participantList.push(videoItem);
      }

      if (item.isScreenShareEnabled) {
        const addScreenShareToGrid =
          (isNonePinned || item.isScreenSharePinned) &&
          maxGridElements > participantGrid.length - 1;

        const shareTrackSid = getByValue(item.videoTracks, Track.Source.ScreenShare);

        if (shareTrackSid) {
          const shareItem = {
            identity: item.identity,
            sid: item.sid,
            isPinned: item.isScreenSharePinned,
            isSelf: item.isSelf,
            trackSid: shareTrackSid,
            isScreenShare: true,
            isSpotlight: item.isSpotlight,
          };

          if (addScreenShareToGrid) {
            participantGrid.push(shareItem);
          } else {
            participantList.push(shareItem);
          }
        }
      }
    }

    // if (participantGrid && participantGrid.length > 0) {
    // 	let grids = [];
    // 	//original elements
    // 	participantGrid.forEach((x) => grids.push(x));
    // 	//duplicate 0th element x times
    // 	[...Array(0).keys()].forEach((x) => grids.push(participantGrid[0]));
    // 	participantGrid = grids;
    // 	console.log('elements in grid', participantGrid.length);
    // }

    // if (participantList && participantList.length > 0) {
    // 	let grids = [];
    // 	//original elements
    // 	participantList.forEach((x) => grids.push(x));
    // 	//duplicate 0th element x times
    // 	[...Array(10).keys()].forEach((x) => grids.push(participantList[0]));
    // 	participantList = grids;
    // 	console.log('elements in list', participantList.length);
    // }

    // this width needs to be calculated keeping the width of parent layout in mind.
    incomingState.grid = participantGrid;
    incomingState.list = participantList;

    if (participantGrid.length == 1) {
      incomingState.columns = 1;
    } else if (participantGrid.length <= 4) {
      incomingState.columns = 2;
    } else if (participantGrid.length <= 9) {
      incomingState.columns = 3;
    } else {
      incomingState.columns = 4;
    }

    incomingState.rows = Math.ceil(participantGrid.length / incomingState.columns);

    console.log('participantState update: processUpdatedParticipantState', incomingState);
    setParticipantState(incomingState);
  };

  useEffect(() => {
    console.log('participantState hook', participantState);

    const participants = Array.from(room.remoteParticipants.values());
    participants.push(room.localParticipant);

    participants.forEach((participant) => {
      Array.from(participant.videoTrackPublications.values()).forEach((publication) => {
        // let videoItem = {
        // 	identity: item.identity,
        // 	sid: item.sid,
        // 	isPinned: item.isVideoPinned,
        // 	isSelf: item.isSelf,
        // 	trackSid: videoTrackSid,
        // 	isScreenShare: false,
        // };

        const isGridItem = !!participantState.grid.find(
          (x) => x.sid === participant.sid && publication.trackSid === x.trackSid
        );

        const viewID = `${participant.sid}:${publication.trackSid}:${isGridItem ? 'grid' : 'list'}`;
        const elements = publication?.track?.attachedElements || [];
        const isAttached = !!elements.find((x) => x.id === viewID);

        // Check if the publication should be detached
        let bind = false;

        if (publication.source === Track.Source.Camera && !participant.isCameraEnabled) {
          bind = true;
        } else if (
          publication.source === Track.Source.ScreenShare &&
          !participant.isScreenShareEnabled
        ) {
          bind = true;
        }

        if (bind || !shouldBindVideo(participant)) {
          console.log('videoElem: detaching: ', publication.trackSid);
          publication?.track?.detach();
        } else {
          const element = document.getElementById(viewID);

          if (element) {
            console.log('videoElem: attaching: ', viewID);
            publication?.track?.attach(element);
          } else {
            console.log('videoElem: not found: ', viewID);
          }
        }
      });
    });
  }, [
    participantState,
    participantStateRef.current,
    room.localParticipant?.isCameraEnabled,
    room.localParticipant?.isScreenShareEnabled
  ]);

  //* callback when the LocalParticipant has subscribed to a track
  const handleTrackSubscribed = (track, publication, participant) => {
    console.log(
      'handleTrackSubscribed: ',
      track.kind,
      track.source,
      track.sid,
      participant.name,
      participant.identity
    );
    setDefaultScreen(participant.name);
    if (publication.kind === Track.Kind.Audio) {
      if (deviceModeRef.current === DeviceMode.Aux) {
        console.log('handleTrackSubscribed, we are on aux device, ignoring track');
        return;
      }

      if (
        publication.trackName.startsWith('custom:participant:') &&
        publication.trackName !== `custom:participant:${userFromParticipant(room.localParticipant)}`
      ) {
        // this is directed audio for that participant
        console.log('handleTrackSubscribed: directed audio, ignoring', publication.trackName);
        return;
      }

      const elements = track?.attachedElements || [];
      if (elements.length > 0) {
        console.log('handleTrackSubscribed: assert failed, elements should be empty');
        track?.detach();
      }

      track.attach();
      return;
    }

    const previousState = { ...participantStateRef.current };

    previousState.all = previousState.all.map((item) => {
      if (item.identity === participant.identity) {
        item.videoTracks.set(track.sid, track.source);

        if (publication.source === Track.Source.Camera) {
          item.videoTracks.delete(DummyTrackSid);
        }

        if (publication.source === Track.Source.ScreenShare) {
          item.isScreenSharePinned = true;
          item.isScreenShareEnabled = true;
        }
      }

      return item;
    });

    processUpdatedParticipantState(previousState);
  };

  //*  callback when a previously subscribed track has been unsubscribed
  const handleTrackUnsubscribed = (track, publication, participant) => {
    console.log('handleTrackUnsubscribed', track, publication, participant);

    track?.detach();

    if (track?.kind === Track.Kind.Audio) {
      return;
    }

    const previousState = { ...participantStateRef.current };

    previousState.all = previousState.all.map((item) => {
      if (item.identity === participant.identity) {
        item.videoTracks.delete(track.sid);

        if (publication.source === Track.Source.ScreenShare) {
          item.isScreenSharePinned = false;
          item.isScreenShareEnabled = false;
        }

        if (publication.source === Track.Source.Camera) {
          item.isVideoPinned = false;
          item.videoTracks.set(DummyTrackSid, Track.Source.Camera);
        }
      }

      return item;
    });

    processUpdatedParticipantState(previousState);
  };

  // callback when a remote participant connects to the room
  const handleParticipantConnected = (participant) => {
    // update the participants list
    console.log(
      'handleParticipantConnected: ',
      participant.identity,
      participant.name,
      participant.sid,
      participantStateRef.current
    );

    if (participant.isLocal) {
      // me connected
      const metadata = JSON.parse(participant.metadata);
      setDeviceMode(metadata?.dev_mode || DeviceMode.Main);
    }

    const previousState = { ...participantStateRef.current };

    previousState.all = previousState.all.filter((item) => {
      if (item.identity === participant.identity) {
        return false;
      }

      return true;
    });

    const item = {
      sid: participant.sid,
      identity: participant.identity,
      isSelf: participant.isLocal,
      isVideoPinned: isVideoPinned,
      isScreenSharePinned: false,
      isScreenShareEnabled: false,
      videoTracks: new Map(),
      isSpotlight: false,
    };

    item.videoTracks.set(DummyTrackSid, Track.Source.Camera);

    previousState.all.push(item);

    processUpdatedParticipantState(previousState);
  };

  const toggleDevice = async () => {
    const body = {
      session_uuid: meetingArgs.session.uuid
    };

    try {
      await callJsonPostApi(urls.livekit_device_mode_switch, body);
    } catch (error) {
      console.error(error);
    }
  };

  const makeMainDevice = async () => {
    setDeviceMode(DeviceMode.Main);

    Array.from(room.remoteParticipants.values()).forEach((participant) => {
      Array.from(participant.audioTracks.values()).forEach((publication) => {
        handleTrackSubscribed(publication.track, publication, participant);
      });
    });

    toast.info(
      'This device is now primary. Audio in/out is supported. Please switch on mic to speak',
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      }
    );
  };

  const makeAuxDevice = async () => {
    try {
      await room.localParticipant.setMicrophoneEnabled(false);
    } catch (e) {
      console.log('makeAuxDevice: setMicrophoneEnabled: false', e);
    }

    Array.from(room.remoteParticipants.values()).forEach((participant) => {
      Array.from(participant.audioTracks.values()).forEach((publication) => {
        handleTrackUnsubscribed(publication?.track, publication, participant);
      });
    });

    toast.info('This device is now aux. Audio in/out is disabled.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000
    });

    setDeviceMode(DeviceMode.Aux);
  };

  const handleParticipantDisconnected = (participant) => {
    console.log('handleParticipantDisconnected: ', participant.identity);

    const previousState = { ...participantStateRef.current };
    previousState.all = previousState.all.filter((item) => item.sid !== participant.sid);

    processUpdatedParticipantState(previousState);
  };

  const handleDisconnected = () => {
    console.log('participantState update self is disconnected');
    setParticipantState(cleanState());
    endCall();
  };

  const shouldBindVideo = (participant) => {
    // if (participant.isLocal || localParticipantInfo.role === 'teacher') {
    // 	return true;
    // }

    if (participant.isLocal || localParticipantInfo.isAdmin) {
      return true;
    }

    const metadata = JSON.parse(participant.metadata);
    if (metadata.role === 'student' && metadata.vid_mode === VideoMode.Teacher) {
      return false;
    }

    return true;
  };

  // callback when a certain track is muted/unmuted
  const handleTrackMuted = (publication, participant) => {
    console.log('handleTrackMuted');

    if (publication.kind === Track.Kind.Video) {
      const elements = publication?.track?.attachedElements || [];

      publication?.track?.detach();

      elements.forEach((element) => {
        element.className = element.className.split(' ').filter((x) => x !== 'hide-bg-image')[0];
      });
    }

    forceUpdate();
  };

  const handleTrackUnMuted = (publication, participant) => {
    console.log('handleTrackUnMuted');
    // update the participants list
    if (publication.kind === Track.Kind.Video) {
      if (!shouldBindVideo(participant)) {
        console.log('video binding disabled:', participant.identity);
        return;
      }

      [
        `${participant.sid}:${publication.trackSid}:grid`,
        `${participant.sid}:${publication.trackSid}:list`
      ].forEach((viewID) => {
        const element = document.getElementById(viewID);

        if (element) {
          element.className = `${element.className} hide-bg-image`;
          publication?.track?.attach(element);
        }
      });
    }

    forceUpdate();
  };

  //* callback fired when a certain participants connection quality changes
  const handleConnectionQualityChanged = (connectionQuality, participant) => {
    console.log('handleConnectionQualityChanged: ', connectionQuality, participant.identity);
    forceUpdate();
  };

  // handler to toggle submenu options for a cetain participant
  const handleToggleParticipantSubMenu = (e, sid) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    let show = false;

    if (showParticipantSubmenu.sid === sid) {
      show = !showParticipantSubmenu.show; // toggle for same
    } else {
      show = true;
    }

    setShowParticipantSubmenu({
      show,
      sid
    });
  };

  // handler to send the custom message to a certain participant
  const handleSubMenuItemTrigger = (option, identity, isScreenShare, status) => {
    console.log('handleSubMenuItemTrigger', option);

    switch (option.type) {
      case 'message.prompt':
        {
          if (option.value === 'Ask to unmute') {
            promptTextToParticipant({
              value: 'Ask to unmute'
            });
          } else {
            promptTextToParticipant(option);
          }
        }
        break;
      case 'actions.Pin_in_view':
        {
          handleParticipantPin(identity, isScreenShare, status);
        }
        break;
      case 'actions.spotlight':
        {
          spotlightRemoteParticipant(option);
        }
        break;
      case 'actions.ban':
        {
          removeParticipantFromMeet(option);
        }
        break;
      case 'actions.mute':
        {
          handleParticipantMute(option);
        }
        break;
      case 'actions.video_to_all':
        {
          handleVideoVisibility(option, VideoMode.Public);
        }
        break;
      case 'actions.video_to_teacher':
        {
          handleVideoVisibility(option, VideoMode.Teacher);
        }
        break;
      case 'actions.screenshot':
        {
          handleTriggerParticipantScreenshot(option);
        }
        break;
      case 'actions.clipStart':
        {
          handleClipAction(option, true);
        }
        break;
      case 'actions.clipStop':
        {
          handleClipAction(option, false);
        }
        break;
      case 'actions.directed_audio':
        {
          if (directedAudio.enabled) {
            handleDirectedAudioStop(option);
          } else {
            handleDirectedAudioStart(option);
          }
        }
        break;
    }

    // close the menu options
    setShowParticipantSubmenu({
      show: false,
      sid: null
    });
  };

  const handleAudioToggle = (e) => {
    if (directedAudio.enabled) {
      handleDirectedAudioStop(e);
    } else {
      handleStartAudioBtn(e);
    }
  };

  const handleDirectedAudioStart = async (option) => {
    console.log('handleDirectedAudio start: ', option, directedAudio);

    if (directedAudio.identity === option.identity) {
      return;
    }

    handleDirectedAudioStop();

    const previousState = { ...directedAudio };

    // disable mic track if running
    try {
      if (room.localParticipant.isMicrophoneEnabled) {
        await room.localParticipant.setMicrophoneEnabled(false);
        previousState.enableMicOnStop = true;
      }
    } catch (e) {
      console.log('handleDirectedAudio: default mic not paused', e);

      toast.error('Default Mic track could not be paused', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });

      return;
    }

    // create new audio mic track with same device id of default track
    const deviceId = await room.localParticipant
      .getTrackPublication(Track.Source.Microphone)
      ?.track?.getDeviceId();
    let mediaContraint = { audio: true };

    if (deviceId) {
      mediaContraint = {
        audio: {
          deviceId
        }
      };
    }

    const participant = room.remoteParticipants.get(option.sid);

    const mediaStream = await navigator.mediaDevices.getUserMedia(mediaContraint);
    const mediaTrack = mediaStream.getAudioTracks()[0];

    // publish the mic track
    try {
      const publication = await room.localParticipant.publishTrack(mediaTrack, {
        name: `custom:participant:${userFromParticipant(option)}`,
        source: Track.Source.Unknown,
        dtx: true,
        red: true
      });

      previousState.trackSid = publication.trackSid;
      previousState.identity = option.identity;
      previousState.enabled = true;
      previousState.name = participant.name;

      setDirectedAudio(previousState);
    } catch (e) {
      console.log('audioFileSelected:publishTrack', e);
    }
  };

  const handleDirectedAudioStop = async (e) => {
    const previousState = { ...directedAudio };

    Array.from(room.localParticipant.trackPublications.values())
      .filter((publication) => publication.trackSid === previousState.trackSid)
      .forEach((publication) => {
        room.localParticipant.unpublishTrack(publication.track, true);
      });

    if (!!e && previousState.enableMicOnStop) {
      handleStartAudioBtn(e, true);
    }

    previousState.identity = '';
    previousState.trackSid = '';
    previousState.enabled = false;
    previousState.name = '';

    if (e) {
      previousState.enableMicOnStop = false;
    }

    setDirectedAudio(previousState);
  };

  const handleTriggerParticipantScreenshot = async (option) => {
    // call the request image upload api
    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity,
      participant_sid: option.sid
    };

    try {
      const res = await callJsonPostApi(urls.livekit_request_image_capture, body);
      const { token } = res.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleClipAction = async (option, isStart) => {
    clearTimeout(onClipStopTimerRef.current);

    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity,
      participant_sid: option.sid
    };

    const endpoint = isStart ? urls.livekit_video_capture_start : urls.livekit_video_capture_stop;

    try {
      const res = await callJsonPostApi(endpoint, body);
      const { token } = res.data.data;

      setIsClipRecOngoing(isStart);

      // auto-stop on 2mins
      if (isStart) {
        onClipStopTimerRef.current = setTimeout(() => {
          handleClipAction(option, false);
        }, 120000); // 2mins in ms //TODO: Move this timer to student repository
      }
    } catch (error) {
      console.error(error);
      setIsClipRecOngoing(!isStart);
    }
  };

  const handleVideoVisibility = async (option, mode) => {
    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity,
      participant_sid: option.sid,
      video_mode: mode
    };

    // maken the api call
    try {
      const res = await callJsonPostApi(urls.livekit_video_mode, body);
      if (res?.data?.status === 'success') {
        // update the participant list
      } else {
        console.log('failed to spotlight participant');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const promptTextToParticipant = async (option) => {
    const payload = {
      type: 'message',
      subType: 'prompt',
      payload: {
        text: option.value
      }
    };

    const targets = [];

    if (option.sid) {
      targets.push(option.sid);
    }
    setTargetParticipants(targets);

    const data = encoder.encode(JSON.stringify(payload));

    // trigger the custom payload here
    room.localParticipant.publishData(data, { reliable: true, destinationIdentities: targets });
  };

  const endSessionForAll = () => {
    const payload = {
        type: 'message',
        subType: 'prompt',
        payload: {
          text: 'end session for all'
        }
    };

    if (room) {
        const data = encoder.encode(JSON.stringify(payload));

        room.localParticipant.publishData(data, { reliable: true, destinationIdentities: targetParticipants })
            .then(() => {
                endCall();
            })
            .catch((error) => {
                console.error('Error publishing data:', error);
            });
    } else {
        console.warn('Room is not available.');
    }
}


  const spotlightRemoteParticipant = async (option) => {
    const isSpotlight = option.label === "Spotlight";
    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity,
      participant_sid: option.sid,
      spotlight: true
    };

    // maken the api call
    try {
      const res = await callJsonPostApi(urls.livekit_spotlight, body);
      if (res?.data?.status === 'success') {
        // update the participant list
        handleParticipantPin(option.identity, false, true, isSpotlight);

        // After 15 seconds, send another request to set spotlight to false
        setTimeout(async () => {
            body.spotlight = false;
            try {
                const res = await callJsonPostApi(urls.livekit_spotlight, body);
                if (res?.data?.status === 'success') {
                    handleParticipantPin(option.identity, false, true, false);
                } else {
                    console.log('failed to remove spotlight from participant');
                }
            } catch (error) {
                console.error('Error removing spotlight:', error);
            }
        }, 15 * 60 * 1000);
      } else {
        console.log('failed to spotlight participant');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // api to ban/remove participant button click
  const removeParticipantFromMeet = async (option) => {
    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity,
      banned: true
    };
    // maken the api call
    try {
      const res = await callJsonPostApi(urls.livekit_ban, body);
      if (res?.data?.status === 'success') {
        // update the participant list
        connectToRoom();
        const previousState = { ...participantStateRef.current };
        processUpdatedParticipantState(previousState);
      } else {
        console.log('failed to remove student from the meet');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // handler to mute participant
  const handleParticipantMute = async (option) => {
    const body = {
      session_uuid: meetingArgs.session.uuid,
      participant_identity: option.identity
    };
    try {
      const res = await callJsonPostApi(urls.livekit_mute_participant, body);
      if (res?.data?.status === 'success') {
        // student is muted/unmuted
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleParticipantMuteToggleAll = async () => {
    const muted = !isAllMuted;

    if (!muted) {
      Array.from(room.remoteParticipants.values()).forEach((participant) => {
        const metadata = JSON.parse(participant.metadata);

        if (metadata.role !== 'student') {
          return;
        }

        if (!participant.isMicrophoneEnabled) {
          promptTextToParticipant({
            // sid: participant.sid,
            value: 'Kindly unmute yourself'
          });
        }
      });
    } else {
      const body = {
        session_uuid: meetingArgs.session.uuid
      };
      try {
        const res = await callJsonPostApi(urls.livekit_mute_participant, body);
        if (res?.data?.status === 'success') {
          // student is muted/unmuted
        }
      } catch (error) {
        console.error(error);
      }
    }

    setAllMuted(muted);
    headerMoreClicked();
  };

  //   callback fired when a custom data is sent from participant
  // const handleDataReceived = (rawInput, participant, kind) => {
  //     let customPayload = JSON.parse(decoder.decode(rawInput));

  //     switch (`${customPayload.type}:${customPayload.subType}`) {
  //         case 'room:participant:spotlight':
  //             {
  //                 if (customPayload.payload?.action?.spotlight) {
  //                     handleParticipantPin(customPayload.payload?.participant?.identity, false, true);
  //                 }
  //             }
  //             break;
  //         case 'message:prompt':
  //             {
  //                 sendToast(`${capitalizeFirstLetter(participant.name) + ': ' + customPayload.payload.text}`, true);
  //             }
  //             break;
  //         case 'transcript:raw':
  //             {
  //                 processCC(customPayload.payload);
  //             }
  //             return;
  //         case 'transcript:asana:match':
  //             {
  //                 // custom message from transcript service
  //                 sendToast(
  //                     <>
  //                         <div>
  //                             <span style={{ textTransform: 'capitalize' }}>
  //                                 🧘‍♀️ {customPayload.payload.key + ' ' + customPayload.payload.text}
  //                             </span>

  //                             {customPayload.payload.data_type === 'image' ? (
  //                                 <img
  //                                     style={{ width: '200px', height: 'auto' }}
  //                                     src={customPayload.payload.image_url}
  //                                     alt={customPayload.payload.key}
  //                                 />
  //                             ) : (
  //                                 <video style={{ width: '200px', height: 'auto' }} autoPlay muted>
  //                                     <source src={customPayload.payload.video_url} type='video/mp4'></source>
  //                                 </video>
  //                             )}
  //                         </div>
  //                     </>,
  //                     customPayload.payload.auto_dismiss
  //                 );
  //             }
  //             break;
  //         case 'room:participant:image:capture:request':
  //             {
  //                 // take the screenshot here
  //                 handleTakeScreenshot(customPayload.payload.action.token);
  //             }
  //             break;
  //         case 'room:participant:image:capture:response':
  //             {
  //                 handleMediaResponse(customPayload.payload);
  //             }
  //             break;
  //         default:
  //             {
  //                 console.log('unrecognized payload received', payload);
  //             }
  //             break;
  //     }

  //     console.log('handleDataReceived', customPayload);
  // };

  //   callback fired when a custom data is sent from participant
  const handleDataReceived = (rawInput, participant) => {
    const customPayload = JSON.parse(decoder.decode(rawInput));

    switch (`${customPayload.type}:${customPayload.subType}`) {
      case 'room:participant:spotlight':
        {
            if (customPayload.payload?.action?.spotlight) {
                handleParticipantPin(customPayload.payload?.participant?.identity, false, true, customPayload.payload?.action?.spotlight);
            }
        }
        // After 15min seconds, call with false as the fourth argument
        setTimeout(() => {
            handleParticipantPin(customPayload.payload?.participant?.identity,false,true,false );
        }, 15 * 60 * 1000);
        break;
      case 'message:prompt':
        sendToast(
          `${`${capitalizeFirstLetter(participant.name)}: ${customPayload.payload.text}`}`,
          true
        );
        break;
      case 'transcript:raw':
        {
          processCC(customPayload.payload);
        }
        return;
      case 'transcript:asana:match':
        sendToast(
          <div>
            <span style={{ textTransform: 'capitalize' }}>
              🧘‍♀️ {`${customPayload.payload.key} ${customPayload.payload.text}`}
            </span>

            {customPayload.payload.data_type === 'image' ? (
              <img
                style={{ width: '200px', height: 'auto' }}
                src={customPayload.payload.image_url}
                alt={customPayload.payload.key}
              />
            ) : (
              <video style={{ width: '200px', height: 'auto' }} autoPlay muted>
                <source src={customPayload.payload.video_url} type="video/mp4" />
              </video>
            )}
          </div>,
          customPayload.payload.auto_dismiss,
          customPayload.payload.key // Unique key for each toast
        );
        break;
      // ... (other cases) ...

      case 'room:participant:image:capture:request':
        {
          // take the screenshot here
          handleTakeScreenshot(customPayload.payload.action.token);
        }
        break;
      case 'room:participant:media:capture:response':
        {
          handleMediaResponse(customPayload.payload);
        }
        break;
      default:
        console.log('unrecognized payload received', customPayload.payload);
        break;
    }
  };

  const handleMediaResponse = (payload) => {
    console.log('handleMediaResponse', payload);

    if (localParticipantInfo.isAdmin) {
      if (payload.action.medias[payload.action.medias?.length - 1].mime === 'video/mp4') {
        toast.info(`Clip captured`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false
        });
      } else {
        toast.info(`Screenshot taken`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false
        });
      }
    }

    // update the images state
    setMediasInSession(payload.action.medias);
  };

  const handleTakeScreenshot = async (token) => {
    if (!room.localParticipant.isCameraEnabled) {
      console.log('camera is not enabled by user:', token);
      return;
    }

    const deviceId = await room.localParticipant
      .getTrackPublication(Track.Source.Camera)
      ?.track?.getDeviceId();
    let mediaContraint = { video: true };
    if (deviceId) {
      mediaContraint = {
        video: {
          deviceId
        }
      };
    }
    const mediaStream = await navigator.mediaDevices.getUserMedia(mediaContraint);
    const videoElement = document.createElement('video');
    videoElement.srcObject = mediaStream;
    await videoElement.play();
    const canvas = document.createElement('canvas');
    canvas.width = 1280;
    canvas.height = 720;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const dataURI = canvas.toDataURL('image/jpeg', 0.7);
    console.log(dataURI, typeof dataURI);
    fetch(dataURI)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'screenshot.jpg', { type: 'image/jpeg' });
        uploadImage(file, token);
      });
    // cleanup
    videoElement.srcObject.getTracks().forEach((track) => track.stop());
  };

  const uploadImage = async (image, token) => {
    const body = new FormData();
    body.append('image', image);
    body.append('token', token);
    try {
      const res = await callJsonPostApi(urls.livekit_upload_image, body, 'formdata');
    } catch (error) {
      console.error(error);
    }
  };

  // sends a toast onto the screen
  // const sendToast = (message, autoClose) => {
  //     console.log('message: ', message);
  //     // setReceivedToastMSG(message);
  //     if (!toast.isActive(toastId.current))
  //         toastId.current = toast(message, {
  //             position: toast.POSITION.BOTTOM_LEFT,
  //             autoClose: autoClose ? 20000 : false,
  //             closeOnClick: false,
  //             progress: false,
  //             theme: 'dark',
  //             className: 'custom-toast-container'
  //         });
  // };

  // State to manage the toasts queue

  const [toastQueue, setToastQueue] = useState([]);

  // State to trigger re-renders when needed
  const [toastCounter, setToastCounter] = useState(0);

  // Function to add toasts to the queue
  const sendToast = (message, autoClose, key) => {
    const [name, messageRecevied] = message.split(':');
    const firstName = name.split(' ')[0];
    if (messageRecevied === ' end session for all') {
        room.disconnect();
    } else if (!toast.isActive(key)) {
        toast(
            <div className="toast-message-received-style">
                <p className="toast-message-name">{firstName}&nbsp;:&nbsp;</p>
                <p
                    className="toast-message-received"
                    style={messageRecevied == ' ❤️' ? { color: 'red' } : {}}
                >
                    {messageRecevied}
                </p>
            </div>,
            {
                position: toast.POSITION.BOTTOM_LEFT,
                closeOnClick: false,
                progress: false,
                theme: 'dark',
                className: 'custom-toast-container',
                hideProgressBar: true,
                toastId: key, // Use the unique key as the toastId
                autoClose: 5000,
                pauseOnHover: true,
                draggable: true,
                theme: 'dark'
            }
        );
    }
  };

  // Function to display the next toast in the queue
  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const { message, autoClose } = toastQueue[0];
      toast(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: autoClose ? 20000 : false,
        closeOnClick: false,
        progress: false,
        theme: 'dark',
        className: 'custom-toast-container',
        onClose: () => {
          // Remove the displayed toast from the queue
          setToastQueue((prevQueue) => prevQueue.slice(1));
          // Trigger the next toast in the queue
          setToastCounter((prevCounter) => prevCounter + 1);
        }
      });
    }
  };

  // useEffect to display the next toast when the queue changes or toastCounter updates
  useEffect(() => {
    displayNextToast();
  }, [toastQueue, toastCounter]);

  //   function to render the cc
  const processCC = (payload) => {
    // trim the text to certain character length and drop the rest
    setClosedCaptions(() =>
      payload.text.length > 100 ? payload.text.slice(payload.text.length - 100) : payload.text
    );
  };

  //   handler for mic options button
  const handleMicOptions = async (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    handleClickOutside();

    try {
      if (micOptions?.show) {
        setMicOptions((prevVal) => ({ ...prevVal, show: false }));
        return;
      }
      // list all microphone devices
      const devices = await Room.getLocalDevices('audioinput');
      // return if no devices are present
      if (devices.length === 0) return;
      setMicOptions({ media: [...devices], show: true });
      // update the active device if activeDevices state is null
      // happens for the first time
      if (!activeDevices.audio) {
        const localTrackPub = await room.localParticipant.getTrackPublication(
          Track.Source.Microphone
        );
        const activeTrackId = await localTrackPub?.track?.getDeviceId();
        setActiveDevices((prevVal) => ({
          ...prevVal,
          audio: activeTrackId
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  //   handler to change the active mic
  const handleMicChange = async (e, device) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    try {
      // Save the selected device to local storage
      localStorage.setItem('activeMicDeviceId', device.deviceId);
      await room.switchActiveDevice('audioinput', device.deviceId);
      // reset the mic options state
      setMicOptions((prevVal) => ({ ...prevVal, show: false }));
      // update the active devices state
      setActiveDevices((prevVal) => ({ ...prevVal, audio: device.deviceId }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getActiveMicDeviceId = localStorage.getItem('activeMicDeviceId');
    if(getActiveMicDeviceId){
        setActiveDevices((prevVal) => ({ ...prevVal, audio: getActiveMicDeviceId }));
    }
  }, [outputOptions.media, activeDevices.audioOut])

  //   handler for camera options button
  const handleCameraOptions = async (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    handleClickOutside();

    try {
      if (camOptions?.show) {
        setCamOptions((prevVal) => ({ ...prevVal, show: false }));
        return;
      }
      // list all camera devices
      const devices = await Room.getLocalDevices('videoinput');
      // return if no devices are present
      if (devices.length === 0) return;
      setCamOptions({ media: [...devices], show: true });
      // update the active device if activeDevices state is null
      // happens for the first time
      if (!activeDevices.video) {
        const localTrackPub = await room.localParticipant.getTrackPublication(Track.Source.Camera);
        const activeTrackId = await localTrackPub?.track?.getDeviceId();
        setActiveDevices((prevVal) => ({
          ...prevVal,
          video: activeTrackId
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  //   handler to change the active camera
  const handleCamChange = async (e, device) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    try {
      await room.switchActiveDevice('videoinput', device.deviceId);
      // reset the cam options state
      setCamOptions((prevVal) => ({ ...prevVal, show: false }));
      // update the active devices state
      setActiveDevices((prevVal) => ({ ...prevVal, video: device.deviceId }));
    } catch (error) {
      console.error(error);
    }
  };

  //   handler to show output options
  const handleOutputOptions = async (e) => {
    handleCameraOptions(e);
    handleMicOptions(e);
    setIsReactionOpen(false);
    e.stopPropagation();
    // allows bypassing react's synthetic event and control native event without having to add a event listener on the window object\
    e.nativeEvent.stopImmediatePropagation();
    handleClickOutside();

    try {
      if (outputOptions.show) {
        setOutputOptions((prevVal) => ({ ...prevVal, show: false }));
        return;
      }
      // list all audio output devices
      const devices = await Room.getLocalDevices('audiooutput');
      // return if no devices are present
      console.log(devices, 'devices');
      if (devices.length === 0) {
        isSafari || isFirefox
          ? triggerInfoModal()
          : setOutputOptions({ media: [...devices], show: true });
        return;
      }

      setOutputOptions({ media: [...devices], show: true });
      // update the active device if activeDevices state is null
      // happens for the first time
      if (!activeDevices.audioOut) {
        const outputDeviceId = await room.getActiveAudioOutputDevice(); // return '' when default output device is selected
        setActiveDevices((prevVal) => ({
          ...prevVal,
          audioOut: outputDeviceId || 'default'
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!outputOptions.media || outputOptions.media.length === 0) {
      return;
    }

    const filteredDevice = outputOptions.media.find(
      (device) =>
        (!device.label.includes('Headphone') || device.label.includes('Headphones')) &&
        device.label.includes('Default')
    );

    if (filteredDevice && !activeDevices.audioOut) {
      setActiveDevices((prevVal) => ({
        ...prevVal,
        audioOut: filteredDevice.deviceId
      }));
    }
  }, [outputOptions.media, activeDevices.audioOut]);

  //   handler to change the audio output
  const handleAudioOutChange = async (e, device) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    try {
      await room.switchActiveDevice('audiooutput', device.deviceId);
      // reset the audio options state
      setOutputOptions((prevVal) => ({ ...prevVal, show: false }));
      // update the active devices state
      setActiveDevices((prevVal) => ({
        ...prevVal,
        audioOut: device.deviceId
      }));
    } catch (error) {
      console.error(error);
    }
  };

  //   handler for click outside to close the open menus on the screen
  const handleClickOutside = useCallback(() => {
    //   participants sub menu options
    showParticipantSubmenu.show &&
      setShowParticipantSubmenu((prevVal) => ({ ...prevVal, show: false }));
    // camera devices
    camOptions.show && setCamOptions((prevVal) => ({ ...prevVal, show: false }));
    // mic devices
    micOptions.show && setMicOptions((prevVal) => ({ ...prevVal, show: false }));
    //   audio output options
    outputOptions.show && setOutputOptions((prevVal) => ({ ...prevVal, show: false }));
    // views options
    // showViews && setShowViews((val) => !val);
    // participants panel
    // showParticipantPanel && setShowParticipantPanel((val) => !val);
    // gallery panel
    //   showGalleryPanel && setShowGalleryPanel((val) => !val);
  }, [
    showParticipantSubmenu,
    camOptions,
    micOptions,
    outputOptions,
    showViews,
    showParticipantPanel
    //   showGalleryPanel,
  ]);

  // function that triggers the info modal for firefox and safari browsers regarding the output device selection
  const triggerInfoModal = () => {
    setLivekitInfoModal({
      show: true,
      onConfirm: () => setLivekitInfoModal({ show: false })
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return '';
  };

  // handler to trigger the screen share
  const handleScreenShare = async () => {
    const screenShareOptions = {
      audio: true,
      selfBrowserSurface: 'exclude',
      systemAudio: 'include',
      suppressLocalAudioPlayback: true
    };

    try {
      await room.localParticipant.setScreenShareEnabled(
        !room.localParticipant.isScreenShareEnabled,
        screenShareOptions
      );
    } catch (error) {
      console.log('handleScreenShare', error);
      // toast.warn('Screen share could not be enabled. Please check browser permissions.', {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 5000,
      // });
    }
  };

  // handler to update the pinned state of participant
  const handleParticipantPin = (identity, isScreenshare, forcePin, spotlight) => {
    // if (forcePin) {
    //     setSelectedView(ViewTypeGallery);
    // }

    const previousState = { ...participantStateRef.current };

    previousState.all = previousState.all.map((item) => {
      if (item.identity === identity) {
        if (forcePin) {
          // Unpin any previously pinned item
          previousState.all.forEach((prevItem) => {
            prevItem.isScreenSharePinned = false;
            prevItem.isVideoPinned = false;
          });
          item.isScreenSharePinned = true;
          item.isVideoPinned = true;
          item.isSpotlight = spotlight;
        } else if (isScreenshare) {
          if (!item.isScreenSharePinned) {
            // Unpin any previously pinned screenshare
            previousState.all.forEach((prevItem) => {
              prevItem.isScreenSharePinned = false;
            });
            item.isScreenSharePinned = true;
          } else {
            item.isScreenSharePinned = false;
          }
        } else if (!item.isVideoPinned) {
          // Unpin any previously pinned video
          previousState.all.forEach((prevItem) => {
            prevItem.isVideoPinned = false;
          });
          item.isVideoPinned = true;
          item.isSpotlight = spotlight;
        } else {
          item.isVideoPinned = false;
        }
      } else if (forcePin) {
        item.isScreenSharePinned = false;
        item.isVideoPinned = false;
        item.isSpotlight = false;
      }

      return item;
    });

    processUpdatedParticipantState(previousState);
    setIsVideoPinned(!isVideoPinned);
  };

  // handler to toggle views
  const handleToggleViews = () => {
    setShowViews(!showViews);
    // console.log(showViews,"|||||");
    // handleClickOutside();
  };

  // handler to change the view
  const handleViewItemClick = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setSelectedView(item);

    // if item is gallery view -> reset the stage view state
    const previousState = { ...participantStateRef.current };
    previousState.all = previousState.all.map((item) => {
      item.isVideoPinned = false;
      item.isScreenSharePinned = false;

      return item;
    });

    processUpdatedParticipantState(previousState);
  };

  //* callback fired when active speaker changes
  const handleActiveSpeakerChange = (participants) => {
    // console.log(participants && participants[0], 'callback fired when active speaker changes')
    // if no participant is talking -> return
    if (participants.length === 0) return;

    // if speaker view, then update the stage view with participant track
    if (selectedViewRef.current !== ViewTypeSpeaker) {
      return;
    }

    console.log(
      'handleActiveSpeakerChange: ',
      participants[0].name,
      participants[0].identity,
      participants[0].sid
    );
    setPersonSpeaking(participants[0].name);

    const previousState = { ...participantStateRef.current };
    let deltaChange = false;

    previousState.all = previousState.all.map((item) => {
      const isPinned = item.sid === participants[0].sid;

      if (item.isVideoPinned !== isPinned) {
        deltaChange = true;
      }

      item.isVideoPinned = isPinned;

      return item;
    });

    if (!deltaChange) {
      return;
    }

    processUpdatedParticipantState(previousState);
  };

  // trigger the fullscreen toggle
  const handleFullScreenToggle = () => {
    // if fullscreen is already active
    if (isFullScreenActive) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
  };

  //   handler to lock the meeting
  const handleLockMeeting = async () => {
    const body = {
      session_uuid: meetingArgs.session.uuid,
      locked: !isMeetingLocked
    };
    try {
      const res = await callJsonPostApi(urls.livekit_lock_meeting, body);
      //   update the respective states on success
      if (res?.data?.status === 'success') {
        setIsMeetingLocked((val) => !val);

        toast.success(`Meeting is now ${isMeetingLocked ? 'unlocked' : 'locked'}`, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        console.log('failed to lock the meeting');
      }
    } catch (error) {
      console.error(error);
    }
  };

  //* callback fired when there's a media device error
  const handleMediaDeviceError = (error) => {
    console.log('handleMediaDeviceError: error: ', error);
    console.log('handleMediaDeviceError: reason: ', MediaDeviceFailure.getFailure(error));

    if (room?.localParticipant?.lastCameraError) {
      console.log(
        'handleMediaDeviceError: lastCameraError: ',
        room.localParticipant.lastCameraError
      );
    }

    if (room?.localParticipant?.lastMicrophoneError) {
      console.log(
        'handleMediaDeviceError: lastMicrophoneError: ',
        room.localParticipant.lastMicrophoneError
      );
    }
  };

  //* callback fired when room recording status changes
  const handleRecordingStatusChanged = (isRecording) => {
    // update the recording status track
    // reset the laoder state for the rec api
    setRecStopInProgress(false);
    if (!localParticipantInfo.isAdmin) {
      forceUpdate();
    }
  };

  //   handler for the settings button
  const handleSettings = () => {
    // trigger the settings modal here
    setSettingsModal({
      show: true,
      onConfirm: () => setSettingsModal({ show: false })
    });
  };

  const handleRoomMetaChanged = (metadata) => {
    console.log('handleRoomMetaChanged:', metadata);
  };

  const handleParticipantMetadataChanged = (_, participant) => {
    console.log(
      'participant metadata changed:',
      participant.name,
      participant.sid,
      participant.identity
    );

    if (participant.identity === room.localParticipant.identity) {
      // device mode toggled
      const metadata = JSON.parse(participant.metadata);
      const nextMode = metadata?.dev_mode || DeviceMode.Main;

      if (deviceModeRef.current === DeviceMode.Main && nextMode === DeviceMode.Aux) {
        makeAuxDevice();
      } else if (deviceModeRef.current === DeviceMode.Aux && nextMode === DeviceMode.Main) {
        makeMainDevice();
      }
    }

    const previousState = { ...participantStateRef.current };
    setParticipantState(previousState);
  };

  const handleAudioPlaybackStatusChanged = () => {
    console.log('handleAudioPlaybackStatusChanged: ', room.canPlaybackAudio);
  };

  const handleParticipantPermissionsChanged = (prevPermissions, participant) => {
    console.log('handleParticipantPermissionsChanged: ', prevPermissions, participant);
  };

  const handleLocalAudioSilence = () => {
    toast.warn(`Audio cannot be detected by system. Please check your system settings.`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000
    });
  };

  //   handler to toggle the rec status
  const handleShowStopRec = () => {
    if (
      meetingArgs.session.type !== 'YOGA' &&
      meetingArgs.session.type != 'YOGA_WITH_FRIENDS_SESSION'
    ) {
      // this is teacher session being recorded, will not stop recording
      return;
    }

    setShowRecStop((val) => !val);
  };

  //   handler to toggle the recording status
  const handleToggleRec = async (stopRec) => {
    // if stopRec is false -> reset the show stop rec state
    if (!stopRec) {
      setShowRecStop(false);
      return;
    }

    // update the show stop rec
    setShowRecStop(false);
    changeRoomRecordingState(false);
  };

  //    changes room recording
  const changeRoomRecordingState = async (state) => {
    const body = {
      session_uuid: meetingArgs.session.uuid,
      record: state
    };
    // update the loader state
    setRecStopInProgress(true);
    try {
      const res = await callJsonPostApi(urls.livekit_stop_rec, body);
      if (res?.data?.status === 'success') console.log('start/stop rec triggered on backend');
      if (state) {
        setRecordStatus(RecordingStatus.OnGoing);
      }
    } catch (error) {
      console.error('error in room rec start', error);
    }
  };

  const showUserRecordingElem = () => {
    if (
      meetingArgs.session.type !== 'YOGA' &&
      meetingArgs.session.type != 'YOGA_WITH_FRIENDS_SESSION'
    ) {
      // this is teacher session being recorded, no UI shown to user
      return null;
    }

    if (room.isRecording) {
      return (
        <div className="menu-item">
          <div className="pulsar" />
          <span>Recording in progress</span>
        </div>
      );
    }

    return (
      <div className="menu-item">
        <span>Not recording</span>
      </div>
    );
  };

  //   onMount > setup the event listeners
  useEffect(() => {
    if (!isConnectedToRoom) {
      // connect to the room
      connectToRoom();
    } else {
      room
        .on(RoomEvent.LocalTrackPublished, handleLocalTrackPublished)
        .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.ParticipantConnected, handleParticipantConnected)
        .on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected)
        .on(RoomEvent.TrackMuted, handleTrackMuted)
        .on(RoomEvent.TrackUnmuted, handleTrackUnMuted)
        .on(RoomEvent.ConnectionQualityChanged, handleConnectionQualityChanged)
        .on(RoomEvent.DataReceived, handleDataReceived)
        .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
        .on(RoomEvent.MediaDevicesError, handleMediaDeviceError)
        .on(RoomEvent.RecordingStatusChanged, handleRecordingStatusChanged)
        .on(RoomEvent.RoomMetadataChanged, handleRoomMetaChanged)
        .on(RoomEvent.ParticipantMetadataChanged, handleParticipantMetadataChanged)
        .on(RoomEvent.AudioPlaybackStatusChanged, handleAudioPlaybackStatusChanged)
        .on(RoomEvent.ParticipantPermissionsChanged, handleParticipantPermissionsChanged)
        .on(RoomEvent.LocalAudioSilenceDetected, handleLocalAudioSilence)
        .on(RoomEvent.Disconnected, handleDisconnected);
    }

    return () => {
      console.log('isConnectedToRoom:useeffect:unmount');

      room
        .off(RoomEvent.LocalTrackPublished, handleLocalTrackPublished)
        .off(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
        .off(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .off(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .off(RoomEvent.ParticipantConnected, handleParticipantConnected)
        .off(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected)
        .off(RoomEvent.TrackMuted, handleTrackMuted)
        .off(RoomEvent.TrackUnmuted, handleTrackUnMuted)
        .off(RoomEvent.ConnectionQualityChanged, handleConnectionQualityChanged)
        .off(RoomEvent.DataReceived, handleDataReceived)
        .off(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
        .off(RoomEvent.MediaDevicesError, handleMediaDeviceError)
        .off(RoomEvent.RecordingStatusChanged, handleRecordingStatusChanged)
        .off(RoomEvent.RoomMetadataChanged, handleRoomMetaChanged)
        .off(RoomEvent.ParticipantMetadataChanged, handleParticipantMetadataChanged)
        .off(RoomEvent.AudioPlaybackStatusChanged, handleAudioPlaybackStatusChanged)
        .off(RoomEvent.ParticipantPermissionsChanged, handleParticipantPermissionsChanged)
        .off(RoomEvent.LocalAudioSilenceDetected, handleLocalAudioSilence)
        .off(RoomEvent.Disconnected, handleDisconnected);
    };
  }, [isConnectedToRoom]);

  //   event listener to handle click outside for submenus on the screen
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [
    showParticipantSubmenu,
    camOptions,
    micOptions,
    outputOptions,
    showViews,
    showParticipantPanel,
    showGalleryPanel
  ]);

  // side effects on fullscreen toggle
  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreenActive(Boolean(document.fullscreenElement));
    };

    document.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);
  const [menuClick, setMenuClick] = useState(false);

  const handleMenuClick = () => {
    setMenuClick(!menuClick);
  };

  const participantElement = (item, displayInGrid, idx) => {
    const participant = getParticipant(item);

    if (!participant || !participant.metadata) {
      // participant or metadata not available
      return null;
    }

    const metadata = JSON.parse(participant.metadata);

    const viewID = `${item.sid}:${item.trackSid}:${displayInGrid ? 'grid' : 'list'}`;

    // if (!!idx && idx > 0) {
    // 	viewID = `${viewID}:${idx}`;
    // }

    const videoBG = item.isScreenShare
      ? `#4A4A4A`
      : `#1a1a1a url(${metadata.avatar}) center/contain no-repeat`;
    let videoClass = displayInGrid ? 'video-view-box-flex' : 'video-view-box';

    const isVideoAvailable = item.isScreenShare
      ? participant.isScreenShareEnabled
      : participant.isCameraEnabled;

    videoClass = isVideoAvailable ? `${videoClass} hide-bg-image` : videoClass;

    const participantSpotlightName = participant?.name?.charAt(0)?.toUpperCase() + participant?.name?.slice(1);

    const getParticipantName = participant.isLocal
            ? `You are now in spotlight. Your video is shared with all participants.`
            : `${participantSpotlightName} is now in spotlight. Their video is shared with all participants.`

    return (
        <div
            key={idx}
            className={`${
            item.isPinned ? 'participant-flex-item-pinned' : 'participant-flex-item'
            } ${participantState.grid.length > 1 ? '' : 'single-member-joined'}
            ${participantState.grid.length === 2 ? 'two-unpinned-particip-grid' : ''}
            `}
            style={
            participantState.grid.length === 2 &&
            currentInnerWidth < 600 &&
            item.isScreenShareEnabled
                ? { height: '50%' }
                : {}
            }
        >
      <div 
        className={`${displayInGrid ? 'participant-box-flex' : 'participant-box'} ${item?.isSpotlight ? 'spotlight-style' : ''}`} 
        key={`${viewID}-box`}
        style={{height: '100%'}}
      >
        <div
          style={isVideoAvailable ? { display: 'none' } : {}}
          className={participantProfileImage}
        >
          <img src={metadata.avatar} />
          {item.isPinned ? (
            <div>
              <p>
                {participant.isLocal
                  ? 'You'
                  : participant.name.charAt(0).toUpperCase() + participant.name.slice(1)}
                {item.isScreenShare && '(Screen Share)'}
              </p>
              {!item.isScreenShare && isConnectedToRoom && (
                <>{participant.isMicrophoneEnabled ? '' : <MuteIcon />}</>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <video
          id={viewID}
          className={videoClass}
          style={!isVideoAvailable ? { display: 'none' } : { background: videoBG }}
        />
        <div
          className={`${
            item.isPinned && !item.isSelf
              ? 'absolute zm:top-10px zm:right-10px md:top-15px md:right-15px cursor-pointer hidden'
              : 'absolute top-0 right-0 z-[1] cursor-pointer mt-15px mr-15px hidden'
          }`}
          onClick={() => handleParticipantPin(item.identity, item.isScreenShare, false)}
          style={
            {
              // display: selectedView === 'Speaker View' ? 'none' : 'block',
            }
          }
        >
          {item.isPinned ? (
            // <TbPinnedOff size='1.5rem' color='#fff' />
            <PinnedIcon />
          ) : (
            <>
              {/* <div className='mobile-pin-video'><MobilePin /></div> */}
              {/* <TbPinned size='1.5rem' color='#fff' /> */}
              <UnPinnedIcon />
            </>
          )}
        </div>

        {/* {!item.isSelf && ( */}
        <div className="participant-overlay-top">
          <div className="sub-menu-container" style={{ marginLeft: 'auto', userSelect: 'none' }}>
            <VideoDropDownMenu
              idx={idx}
              isItemPinned={item.isPinned}
              data={buildMenuOptions(localParticipantInfo, participant, metadata)}
              onSelect={(option) =>
                handleSubMenuItemTrigger(option, item.identity, item.isScreenShare, false)
              }
              getDropDownStatus={(isDropDownOpen) => setIsVideoDropDown(isDropDownOpen)}
              getParticipantName={
                participant.name.charAt(0).toUpperCase() + participant.name.slice(1)
              }
              isClipRecOngoing={isClipRecOngoing}
            />
            {/* <Dropdown
                    items={buildMenuOptions(localParticipantInfo, participant, metadata)}
                    onSelect={(val, option) => handleSubMenuItemTrigger(option)}
                    className='participant-sub-menu'
                    containerWidth={200}
                    children={(params) => {
                        return <AiOutlineMenu size='1.5rem' color='#fff' onClick={params.onClick} />;
                    }}
                ></Dropdown> */}

            {/* {showParticipantSubmenu?.show && showParticipantSubmenu?.sid === item.sid && (
								
			    )} */}
          </div>
        </div>
        {/* )} */}

        <div
          className={`participant-overlay-bottom participant-overlay-bottom-new ${
            item.isPinned ? 'hide-participant-overlay-bottom-mobile' : ''
          }`}
        >
          <p className="ellipseText max-w-[185px]">
            {participant.isLocal
              ? 'You'
              : participant.name.charAt(0).toUpperCase() + participant.name.slice(1)}
            {item.isScreenShare && '(Screen Share)'}
          </p>
          {!item.isScreenShare && isConnectedToRoom && (
            <>
              {participant.isMicrophoneEnabled ? (
                // <BsFillMicFill color='#ED731B' size='1.2rem' key={`${participant.identity}-mic-on`} />
                ''
              ) : (
                // <BsFillMicMuteFill
                // 	color='crimson'
                // 	size='1.2rem'
                // 	key={`${participant.identity}-mic-off`}
                // />
                <MuteIcon />
              )}
            </>
          )}
          {/* <p className='participant-overlay-new'>
						{capitalizeFirstLetter(participant.name)} {participant.isLocal && '(You)'}{' '}
						{item.isScreenShare && '(Screen Share)'}
					</p> */}

          {/* {isConnectedToRoom && (
						<AiFillSignal
							color={
								participant.connectionQuality === ConnectionQuality.Excellent
									? '#04A777'
									: participant.connectionQuality === ConnectionQuality.Good
									? 'orange'
									: 'crimson'
							}
						/>
					)} */}
        </div>
        {item?.isSpotlight && !item.isScreenShare &&
            <NotificationBadge show={item?.isSpotlight} text={`${getParticipantName}`}/>
        }
      </div>
      </div>
    );
  };

  const [audioPlayInfo, _setAudioPlayInfo] = useState({
    show: false,
    trackSid: null,
    file: null
  });

  const audioPlayInfoRef = useRef();
  audioPlayInfoRef.current = audioPlayInfo;

  const setAudioPlayInfo = (state) => {
    audioPlayInfoRef.current = state;
    _setAudioPlayInfo(state);
  };

  const stopFileAudioTrack = () => {
    console.log('stopFileAudioTrack');

    const state = { ...audioPlayInfoRef.current };

    Array.from(room.localParticipant.trackPublications.values())
      .filter((publication) => publication.trackSid === state.trackSid)
      .forEach((publication) => {
        room.localParticipant.unpublishTrack(publication.track, true);
      });

    const audioElem = document.getElementById('admin-shared-audio');
    if (audioElem) {
      audioElem.src = '';
    }

    state.trackSid = null;
    state.file = null;

    setAudioPlayInfo(state);
  };

  const handleAudioFileToggle = () => {
    audioPlayInfoRef.current.show && stopFileAudioTrack();

    const state = { ...audioPlayInfoRef.current };
    state.show = !state.show;

    setAudioPlayInfo(state);
  };

  const audioFileSelected = async (e) => {
    const { files } = e.target;
    if (files.length !== 1) {
      return;
    }

    stopFileAudioTrack();

    const state = { ...audioPlayInfoRef.current };

    state.file = URL.createObjectURL(files[0]);
    setAudioPlayInfo(state);

    e.target.value = '';

    const audioElem = document.getElementById('admin-shared-audio');

    if (!audioElem.mozCaptureStream && !audioElem.captureStream) {
      toast.info('Please use latest chrome, firefox, edge, safari to use this feature', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      });

      return;
    }

    audioElem.volume = 1.0;
    audioElem.muted = false;
    audioElem.src = state.file;

    audioElem.oncanplay = async () => {
      console.log('oncanplay');

      const state = { ...audioPlayInfoRef.current };

      if (state.trackSid) {
        return;
      }

      const stream = audioElem.mozCaptureStream
        ? audioElem.mozCaptureStream()
        : audioElem.captureStream();
      const mediaTrack = stream.getAudioTracks()[0];

      try {
        const publication = await room.localParticipant.publishTrack(mediaTrack, {
          name: 'custom:media',
          source: Track.Source.Unknown,
          dtx: true,
          red: true
        });

        state.trackSid = publication.trackSid;
      } catch (e) {
        console.log('audioFileSelected:publishTrack', e);
      }

      setAudioPlayInfo(state);
    };

    audioElem.onplay = async () => {
      console.log('audioFileSelected: playing');
    };

    audioElem.onpause = () => {
      console.log('audioFileSelected: paused');
    };

    audioElem.onended = () => {
      console.log('audioFileSelected: ended');
      stopFileAudioTrack();
    };

    audioElem.onerror = () => {
      console.log('audioFileSelected: onerror');
      stopFileAudioTrack();
    };

    audioElem.play();
  };

  const videoVisibilityMenuView = () => {
    const encodedMetadata = room?.localParticipant?.metadata;

    if (!encodedMetadata) {
      return;
    }

    const onlyTeacherCanSeeMe = JSON.parse(encodedMetadata)?.vid_mode === VideoMode.Teacher;

    const option = {
      identity: room.localParticipant.identity,
      sid: room.localParticipant.sid
    };

    const nextState = onlyTeacherCanSeeMe ? VideoMode.Public : VideoMode.Teacher;

    return (
      <div
        className="media-control cursor-pointer"
        onClick={() => handleVideoVisibility(option, nextState)}
      >
        <span>
          <BsFillCameraVideoFill
            color="#ED731B"
            size="1.5rem"
            data-tooltip-id="video-tooltip"
            data-tooltip-content="Toggle camera video visibility"
          />
        </span>
        <span>
          {onlyTeacherCanSeeMe ? (
            <BsPersonVideo
              color="#ED731B"
              size="1.5rem"
              data-tooltip-id="video-tooltip"
              data-tooltip-content="Only teacher can see you. "
            />
          ) : (
            <BsPersonVideo2
              color="#ED731B"
              size="1.5rem"
              data-tooltip-id="video-tooltip"
              data-tooltip-content="Video is visible to everyone."
            />
          )}
        </span>
        <span className="device-label cursor-pointer" style={{ color: '#fff' }}>
          {onlyTeacherCanSeeMe ? 'To Teacher' : 'To All'}
        </span>
      </div>
    );
  };

  const handleMediaClick = (image) => {
    // trigger the image viewer modal
    setMediaViewerModal((prevVal) => ({
      ...prevVal,
      show: true,
      medias: [...mediasInSession],
      selectedMedia: image,
      onConfirm: () => setMediaViewerModal({ show: false, medias: [] })
    }));
  };

  const headerMoreClicked = () => {
    setHeaderShowMore(!headerShowMore);
  };

  const openReactionHandler = () => {
    setIsReactionOpen(!isReactionOpen);
  };

  const filterUniqueNames = (names) => {
    const uniqueNames = [];
    names.forEach((name) => {
      if (!uniqueNames.includes(name)) {
        uniqueNames.push(name);
      }
    });
    return uniqueNames;
  };

  // const participantNames = Array.from(room.remoteParticipants.values()).filter(participant => capitalizeFirstLetter(participant.identity).includes('student')).map(item => capitalizeFirstLetter(item.name));
  const participantNames = filterUniqueNames(
    Array.from(room.remoteParticipants.values())
      .filter((participant) => capitalizeFirstLetter(participant.identity).includes('student'))
      .map((item) => capitalizeFirstLetter(item.name))
  );

  const getNetworkConnectionQuality = () => {
    const localQuality = room.localParticipant._connectionQuality;

    switch (localQuality) {
      case ConnectionQuality.Excellent:
      case ConnectionQuality.Good:
        return 'stable';
      case ConnectionQuality.Poor:
        return 'unstable';
      default:
        return 'unknown';
    }
  };
  const participantFlexClasses = [
    'participant-flex-content-wrapper',
    isVideoPinned || personSpeaking || defaultScreen
      ? participantState.list.length === 0
        ? ''
        : 'pinnedVideoStyle'
      : '',
    participantState.list.length === 0 ? 'all-unpinned-participant-flex-content-wrapper' : '',
    participantState.grid.length === 1 ? 'oneParticipantsInMeeting' : '',
    participantState.list.length >= 1 ? 'pinnedVideoStyle' : ''
  ].join(' ');

  const participantProfileImage = [
    'participant-box-image-container',
    isVideoPinned || personSpeaking || defaultScreen
      ? participantState.list.length === 0
        ? ''
        : 'participant-gallery-profile-image'
      : '',
    participantState.list.length === 0 ? 'all-unpinned-participant-box-image-container' : '',
    Array.from(room.remoteParticipants.values()).length === 0
      ? 'participant-gallery-profile-image'
      : '',
    participantState.grid.length === 1 ? 'participant-gallery-profile-image' : '',
    participantState.grid.length === 2 ? 'participant-gallery-profile-image' : '',
    ,
  ].join(' ');

  const participantFlexContent = [
    'participant-flex-content',
    participantState.list.length === 0 ? 'all-unpinned-participant-flex-content' : '',
    participantState.grid.length === 2 ? 'two-unpinned-particip' : '',
    participantState.grid.length > 2 ? 'wrap-participants' : '',
    
  ].join(' ');

  useEffect(() => {
    if (isVideoPinned) {
      _setSelectedView('');
    }
  }, [isVideoPinned]);

  window.addEventListener('blur', () => {
    toast.dismiss();
  });

  useEffect(() => {
    const handleClickOutsidePopup = (event) => {
      if (reactionRef.current && !reactionRef.current.contains(event.target)) {
        setIsReactionOpen(false);
      }
      if (networkStatusRef.current && !networkStatusRef.current.contains(event.target)) {
        setShowStatusPopup(false);
      }
      if (viewRef.current && !viewRef.current.contains(event.target)) {
        setShowViews(false);
      }
      if (headerMoreRef.current && !headerMoreRef.current.contains(event.target)) {
        setHeaderShowMore(false);
      }
    };

    document.addEventListener('click', handleClickOutsidePopup);

    return () => {
      document.removeEventListener('click', handleClickOutsidePopup);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCurrentInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderRecordingAction = () => (
    <>
      {showRecStop && (
        <div>
          <RecordingPopUp yes={() => handleToggleRec(true)} no={() => handleToggleRec(false)} />
          {/* <span className='recording-text'>Recording</span> */}
          {/* <span>Stop recording?</span>
                            <button onClick={() => handleToggleRec(true)}>yes</button>
                            <button onClick={() => handleToggleRec(false)}>no</button> */}
        </div>
      )}
      {recStopInProgress ? (
        <span>
          <BiLoader className="loader" color="#FFF" size="1.2rem" />
        </span>
      ) : RecordingStatus.OnGoing && room.isRecording ? (
        <div onClick={handleShowStopRec}>
          <span className="recording-text">Recording</span>
        </div>
      ) : (
        <div onClick={() => changeRoomRecordingState(true)}>
          <span className="recording-text">Start recording</span>
        </div>
      )}
    </>
  );

  const getParticipantsJoined = () => {
    const participantsSize = new Set(
      participantState?.all.map((participant) => participant.identity.split(':')[0])
    );
    setNumberOfParticipantsJoined(participantsSize.size);
  };

  const isMediaFeatureAccessible = () =>
    participantState?.all.some((item) =>
      AccessSessionClipStudents.includes(userFromParticipant(item))
    );

  useEffect(() => {
    getParticipantsJoined();
  }, [participantState]);

  const getHeightStyle = () => {
    if (liveKitScreenHeight) {
      if (isSafari) {
        return {
          height: 'calc(100vh)' // for Safari
        };
      }
      if (isSafari && isTablet) {
        return {
          height: 'calc(100vh - 30px)' // for Safari tablet
        };
      }
      return {
        height: '-webkit-fill-available' // for other iOS devices
      };
    }
    return {
      height: '100%' // for other devices
    };
  };

  return (
    <>
      <LiveKitErrorModal {...errorModal} />
      <ReactTooltip
        id="video-tooltip"
        variant="dark"
        place="top"
        aria-haspopup="true"
        className="w-80"
        style={{
          backgroundColor: '#ED731B',
          zIndex: 99
        }}
      />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
        limit={4}
      />
      <SessionEndedModal {...customModal} />
      {/* <LivekitInfoModal {...livekitInfoModal} /> */}
      <SettingsModal
        {...settingsModal}
        key={String(settingsModal.show)}
        triggerInfoModal={triggerInfoModal}
      />
      <MediaViewerPlayer {...mediaViewerModal} />
      <div className="flex" style={getHeightStyle()}>
        <div className="relative w-full liveKit-video-main-container">
          {/* Livekit Top Navbar Start */}
          <div className="top-navebar">
            <div className="flex justify-between w-full relative">
              <div className="top-navebar-section-one zm:w-38 xs:w-auto">
                <div className="top-navebar-class-name">1-on-1 session</div>
                {participantNames ? (
                  <div className="top-navebar-teacher-name ellipseText">{participantNames}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="top-navebar-section-two">
                <div
                  className={`control-container-style zm:hidden md:flex ${
                    room.isRecording ? 'isRecording' : 'isRecordingStop'
                  }`}
                >
                  {localParticipantInfo.isAdmin && renderRecordingAction()}
                  <div>
                    {!recStopInProgress ? (
                      room.isRecording ? (
                        <VideoRecordingStart />
                      ) : (
                        <VideoRecordingStop />
                      )
                    ) : null}
                  </div>
                </div>
                {/* <div className='control-container'>
                        <div>Mute all</div>
                        <div><UnMuteCall/></div>
                    </div> */}
                {localParticipantInfo.isAdmin && (
                  <div
                    className="media-control cursor-pointer bg-vidgrey py-2 px-11px newMuteAll-desktop"
                    onClick={handleParticipantMuteToggleAll}
                  >
                    <span className="device-label cursor-pointer text-white text-15px font-semibold">
                      {isAllMuted ? 'Ask to unmute' : 'Mute All'}
                    </span>
                    <span>
                      {isAllMuted ? (
                        // <BsFillMicMuteFill
                        // 	size='1.5rem'
                        // 	color='#ED731B'
                        // 	data-tooltip-id='video-tooltip'
                        // 	data-tooltip-content='Class is muted, Ask to unmute'
                        // />
                        <div>
                          <MuteAllIcon />
                        </div>
                      ) : (
                        // <BsFillMicFill
                        // 	size='1.5rem'
                        // 	color='#cbcbcb'
                        // 	data-tooltip-id='video-tooltip'
                        // 	data-tooltip-content='Mute Class'
                        // />
                        <div>
                          <UnMuteCall />
                        </div>
                      )}
                    </span>
                  </div>
                )}
                <div
                  ref={viewRef}
                  className="control-container control-container-view"
                  onClick={handleToggleViews}
                >
                  <div>View</div>
                  <div>
                    <Views />
                  </div>
                  {showViews && (
                    <div className="show-views-container">
                      {VIEWS.map((item, index) => (
                        <div key={index}>
                          <div
                            className="show-views-item"
                            style={{ color: selectedView !== item ? '' : '#ED731B' }}
                            onClick={(e) => {
                              handleViewItemClick(e, item);
                              handleToggleViews();
                            }}
                          >
                            <OrangeTick
                              style={{
                                visibility: selectedView !== item ? 'hidden' : 'visible'
                              }}
                            />
                            {item}
                          </div>
                        </div>
                      ))}
                      <div
                        className="show-views-item view-item-alt"
                        onClick={handleFullScreenToggle}
                      >
                        {isFullScreenActive ? (
                          <AiOutlineFullscreenExit color="#ED731B" size="1rem" />
                        ) : (
                          <AiOutlineFullscreen color="#ED731B" size="1rem" />
                        )}
                        {isFullScreenActive ? 'Exit ' : 'Go '}Fullscreen
                      </div>
                    </div>
                  )}
                </div>
                <div ref={headerMoreRef} className="control-container control-container-more">
                  <div onClick={headerMoreClicked}>
                    <MoreThreeDots />
                  </div>
                  {headerShowMore
                    ? localParticipantInfo.isAdmin && (
                        <div
                          className="media-control cursor-pointer bg-vidgrey py-2 px-11px newMuteAll-mobile"
                          onClick={handleParticipantMuteToggleAll}
                        >
                          <span
                            className="device-label cursor-pointer text-white text-15px font-semibold"
                            style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.65)' }}
                          >
                            {isAllMuted ? 'Ask to unmute' : 'Mute All'}
                          </span>
                          <span>
                            {isAllMuted ? (
                              // <BsFillMicMuteFill
                              // 	size='1.5rem'
                              // 	color='#ED731B'
                              // 	data-tooltip-id='video-tooltip'
                              // 	data-tooltip-content='Class is muted, Ask to unmute'
                              // />
                              <div>
                                <MuteAllIcon />
                              </div>
                            ) : (
                              // <BsFillMicFill
                              // 	size='1.5rem'
                              // 	color='#cbcbcb'
                              // 	data-tooltip-id='video-tooltip'
                              // 	data-tooltip-content='Mute Class'
                              // />
                              <div>
                                <UnMuteCall />
                              </div>
                            )}
                          </span>
                        </div>
                      )
                    : null}
                </div>
                <div className="control-container" onClick={handleTogglePaticipantPanel}>
                  <div>
                    {Array.from(room.remoteParticipants.values())?.length +
                      (room.localParticipant.name ? 1 : 0)}
                  </div>
                  <div>
                    <Participants />
                  </div>
                  {showParticipantPanel ? (
                    <div className="participants-gallery-pannel">
                      <ParticipantsListPannel
                        participantsList={{
                          getParticipant: Array.from(room.remoteParticipants.values()),
                          localParticipant: room.localParticipant
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {isMediaFeatureAccessible() ? (
                  <div className="control-container" onClick={handleToggleGalleryPanel}>
                    <div>
                      <GalleryIcon />
                    </div>
                    {showGalleryPanel ? (
                      <div className="participants-gallery-pannel">
                        <SessionImagesDrawer
                          mediasInSession={mediasInSession}
                          handleMediaClick={handleMediaClick}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div
                  ref={networkStatusRef}
                  className="control-container control-container-network-mobile"
                  onClick={() => setShowStatusPopup(!showStatusPopup)}
                >
                  <NetworkSignal status={getNetworkConnectionQuality()} />
                  <div className="network-connection-component">
                    <NetworkConnectionStatus
                      status={getNetworkConnectionQuality()}
                      showNetworkPopup={showStatusPopup}
                    />
                  </div>
                </div>
              </div>
              <div className="zm:flex md:hidden recording-control-container-mobile">
                <div
                  className={`control-container-style zm:flex md:hidden ${
                    room.isRecording ? 'isRecording' : 'isRecordingStop'
                  }`}
                >
                  {localParticipantInfo.isAdmin && renderRecordingAction()}
                  <div>
                    {!recStopInProgress ? (
                      room.isRecording ? (
                        <VideoRecordingStart />
                      ) : (
                        <VideoRecordingStop />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='livekit-vc' style={{ alignItems: 'flex-start' }} id='livekit-vc-screen'> */}

          {/* <div className='menu-bar'> */}
          {/* toggle views section */}
          {/* <div className='menu-item' onClick={handleToggleViews}>
						<MdViewQuilt color='#cbcbcb' size='1.2rem' />
						<span>Views</span>
					</div> */}
          {/* admin recording status indicator */}
          {/* {localParticipantInfo.isAdmin && (
						<>
							{showRecStop ? (
								<div className='menu-item'>
									<div className='pulsar'></div>
									<span>Stop recording?</span>
									<button onClick={() => handleToggleRec(true)}>yes</button>
									<button onClick={() => handleToggleRec(false)}>no</button>
								</div>
							) : recStopInProgress ? (
								<span>
									<BiLoader className='loader' color='#ED731B' size='1.5rem' />
								</span>
							) : room.isRecording ? (
                                <div className='menu-item bg-vidred newMuteAll' onClick={handleShowStopRec}>
                                    <span className='text-white text-15px font-semibold'>Recording</span>
									<div className='pulsar'></div>
								</div>
							) : (
								<div className='menu-item' onClick={() => changeRoomRecordingState(true)}>
									<span>Click here to start recording</span>
								</div>
							)}
						</>
					)} */}

          {/* non-admin recording indicator */}
          {/* {!localParticipantInfo.isAdmin && showUserRecordingElem()} */}
          {/* </div> */}

          {/* {showViews && (
					<div className='view-container'>
						{VIEWS.map((item, index) => {
							return (
								<div key={index}>
									<div className='view-item' onClick={(e) => handleViewItemClick(e, item)}>
										<TiTick
											color='#ED731B'
											size='1rem'
											style={{
												visibility: selectedView !== item ? 'hidden' : 'visible',
											}}
										/>
										{item}
									</div>
									<div className='separator'></div>
								</div>
							);
						})}
						<div className='view-item view-item-alt' onClick={handleFullScreenToggle}>
							{isFullScreenActive ? (
								<AiOutlineFullscreenExit color='#ED731B' size='1rem' />
							) : (
								<AiOutlineFullscreen color='#ED731B' size='1rem' />
							)}
							{isFullScreenActive ? 'Exit ' : 'Go '}Fullscreen
						</div>
					</div>
				)} */}
          {/* </div> */}
          <div className={participantFlexClasses}>
            <div
              className={participantFlexContent}
              style={
                participantState.grid.length === 1
                  ? { height: '100%' }
                  : selectedView === 'Grid view' ||
                      selectedView === 'Speaker View' ||
                      participantState.list.length === 0
                    ? { height: 'unset' }
                    : {}
              }
              // style={{
              //     width: participantState.list.length === 0 ? '100%' : '80%',
              //     gridTemplateColumns: `repeat(${participantState.columns}, 1fr)`,
              //     gridTemplateRows: `repeat(${participantState.rows}, 1fr)`,
              // }}
            >
              {participantState.grid.map((item, idx) => {
                return (
                
                  participantElement(item, true, idx)
               
              )})}
            </div>
          </div>

          <div
            className="participant-vertical-content"
            style={{
              alignItems: 'flex-start',
              display: participantState.list.length > 0 ? 'flex' : 'none',
              overflow: 'scroll'
            }}
          >
            <div>{participantState.list.map((item, idx) => participantElement(item, false))}</div>
          </div>

          {/* bottom media controls */}
          <div className="media-controls-container livekit-mcc">
            {/* <img className='footer-logo' src='https://lifetreehealth.com/images/logoLifeTreeHealth.png' /> */}
            <div className="absolute left-25px sm:block zm:hidden">
              <VideoLogo />
            </div>
            {/* myt footer logo */}
            {/* <div className="mcc-myt-logo"></div> */}

            {/* audio controls */}
            {deviceMode === DeviceMode.Main && (
              <div
                className={`${
                  room.localParticipant.isMicrophoneEnabled || directedAudio.enabled
                    ? 'reaction-wrapper-section'
                    : 'bg-vidred'
                } md:w-50px zm:w-46px md:h-50px zm:h-46px flex justify-center items-center rounded-full`}
                data-tooltip-id="video-tooltip"
                data-tooltip-content={
                  currentInnerWidth > 1100
                    ? room.localParticipant.isMicrophoneEnabled || directedAudio.enabled
                      ? 'Mute'
                      : 'Unmute'
                    : ''
                }
              >
                <span onClick={handleAudioToggle} className="cursor-pointer">
                  {room.localParticipant.isMicrophoneEnabled || directedAudio.enabled ? (
                    // <BsFillMicFill
                    // 	data-tooltip-id='video-tooltip'
                    // 	data-tooltip-content='Mute'
                    // 	size='1.7rem'
                    // 	color='#ED731B'
                    // />
                    <div>
                      <UnmuteIcon />
                    </div>
                  ) : (
                    // <BsFillMicMuteFill
                    // 	data-tooltip-id='video-tooltip'
                    // 	data-tooltip-content='Unmute'
                    // 	size='1.7rem'
                    // 	color='crimson'
                    // />
                    <AdioMuteIcon />
                  )}
                </span>
                <span
                  onClick={handleAudioToggle}
                  className="device-label cursor-pointer"
                  style={{ color: '#fff' }}
                >
                  {directedAudio.enabled
                    ? `To ${capitalizeFirstLetter(directedAudio.name.split(' ')[0])}`
                    : room.localParticipant.isMicrophoneEnabled
                      ? ''
                      : ''}
                </span>
                {/* <span className='mc-icon cursor-pointer' onClick={handleMicOptions}>
                                    <CiSquareMore
                                    color='#fff'
                                    size='1.5rem'
                                    data-tooltip-id='video-tooltip'
                                    data-tooltip-content='Change audio input device'
                                    />
                                </span> */}
                {/* {micOptions.show && (
                                    <div
                                        className='devices-container'
                                        style={{
                                            marginTop: `-${micOptions?.media?.length > 2 ? micOptions.media.length * 4 : 10}rem`,
                                        }}
                                    >
                                        {micOptions.media.map((device, index) => {
                                            return (
                                                <>
                                                    <div
                                                        className='device-item cursor-pointer'
                                                        key={device.deviceId}
                                                        onClick={(e) => handleMicChange(e, device)}
                                                    >
                                                        <TiTick
                                                            color='#ED731B'
                                                            size='1rem'
                                                            style={{
                                                                visibility:
                                                                    activeDevices.audio === device.deviceId ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                        {device.label}
                                                    </div>
                                                    {index !== micOptions.media.length - 1 && <div className='separator'></div>}
                                                </>
                                            );
                                        })}
                                    </div>
                                )} */}
              </div>
            )}
            {/* video controls */}
            <div
              className={`${
                room.localParticipant.isCameraEnabled ? 'reaction-wrapper-section' : 'bg-vidred'
              } md:w-50px zm:w-46px md:h-50px zm:h-46px flex justify-center items-center rounded-full`}
              data-tooltip-id="video-tooltip"
              data-tooltip-content={
                currentInnerWidth > 1100
                  ? room.localParticipant.isCameraEnabled
                    ? 'Video Off'
                    : 'Video On'
                  : ''
              }
            >
              <span className="mc-icon camera-border cursor-pointer" onClick={handleStartVideoBtn}>
                {room.localParticipant.isCameraEnabled ? (
                  // <BsFillCameraVideoFill
                  // 	size='2rem'
                  // 	data-tooltip-id='video-tooltip'
                  // 	data-tooltip-content='Stop camera'
                  // 	color='white'
                  // />
                  <div>
                    <CameraOnIcon />
                  </div>
                ) : (
                  // <BsFillCameraVideoOffFill
                  // 	data-tooltip-id='video-tooltip'
                  // 	data-tooltip-content='Start camera'
                  // 	size='2rem'
                  // 	color='crimson'
                  // />
                  <CameraOffIcon />
                )}
              </span>
              {/* <span className='mc-icon cursor-pointer gggg' onClick={handleCameraOptions}>
						    <CiSquareMore
							color='#fff'
							size='1.5rem'
							data-tooltip-id='video-tooltip'
							data-tooltip-content='Change camera device'
						    />
					        </span> */}
              {/* {camOptions.show && (
                                <div
                                    className='devices-container'
                                    style={{
                                        marginTop: `-${camOptions?.media?.length > 2 ? camOptions.media.length * 4 : 10}rem`,
                                    }}
                                >
                                    {camOptions.media.map((device, index) => {
                                        return (
                                            <>
                                                <div
                                                    className={`device-item cursor-pointer`}
                                                    key={device.deviceId}
                                                    onClick={(e) => handleCamChange(e, device)}
                                                >
                                                    <TiTick
                                                        color='#ED731B'
                                                        size='1rem'
                                                        style={{
                                                            visibility:
                                                                activeDevices.video === device.deviceId ? 'visible' : 'hidden',
                                                        }}
                                                    />
                                                    {device.label}
                                                </div>
                                                {index !== camOptions.media.length - 1 && <div className='separator'></div>}
                                            </>
                                        );
                                    })}
                                </div>
                            )} */}
            </div>

            {/* screen share controls */}
            {localParticipantInfo.isAdmin && deviceMode === DeviceMode.Main && (
              <div
                className={`${
                  !room.localParticipant.isScreenShareEnabled
                    ? 'reaction-wrapper-section'
                    : 'bg-vidred'
                } md:w-50px zm:w-46px md:h-50px zm:h-46px flex justify-center items-center rounded-full`}
                data-tooltip-id="video-tooltip"
                data-tooltip-content={currentInnerWidth > 1100 ? 'Screen Share' : ''}
                style={currentInnerWidth > 1000 ? { display: 'flex' } : { display: 'none' }}
              >
                <span onClick={handleScreenShare} className="cursor-pointer">
                  {/* {room.localParticipant.isScreenShareEnabled ? (
                                        <MdStopScreenShare color='white' size='1.5rem' />
                                    ) : (
                                        // <MdScreenShare color='#ED731B' size='1.5rem' /> */}
                  <div>
                    <ScreenShareIcon />
                  </div>
                  {/* )} */}
                </span>
              </div>
            )}
            {/* Emoji Controls */}
            {numberOfParticipantsJoined < 3 ? (
              <div className="reaction-wrapper" ref={reactionRef}>
                <div
                  className="reaction-wrapper-section"
                  data-tooltip-id="video-tooltip"
                  data-tooltip-content={currentInnerWidth > 1100 ? 'Reactions' : ''}
                  onClick={openReactionHandler}
                >
                  <EmojiIcon />
                </div>
                {isReactionOpen ? (
                  <ReactionModal
                    data={{
                      localParticipant: localParticipantInfo,
                      getParticipant: Array.from(room.remoteParticipants.values())
                    }}
                    onSelect={(option) => handleSubMenuItemTrigger(option)}
                  />
                ) : null}
              </div>
            ) : (
              <></>
            )}

            {/* output controls */}
            <div className="reaction-wrapper-section relative">
              <span
                onClick={handleOutputOptions}
                data-tooltip-id="video-tooltip"
                data-tooltip-content={currentInnerWidth > 1100 ? 'Audio & Video Settings' : ''}
              >
                {/* <BsFillSpeakerFill color='#ED731B' size='1.5rem' /> */}
                <MoreMenuIcon />
              </span>
              {outputOptions.show && (
                <div
                  className="devices-container more-menu z-10 w-320px"
                  style={{
                    marginTop: `-${
                      outputOptions?.media?.length > 2 ? outputOptions.media.length * 4 : 10
                    }rem`,
                    maxHeight: '400px',
                    overflowY: 'scroll'
                  }}
                >
                  <div className="device-item cursor-pointer more-menu-item z-50 relative tickoption">
                    {outputOptions.media.some(
                      (device) =>
                        device.label.includes('Headphone') || device.label.includes('Headphones')
                    ) && <div className="speaker-item">Select a microphone</div>}
                    {deviceMode === DeviceMode.Main &&
                      outputOptions.media
                        .filter(
                          (device) =>
                            device.label.includes('Headphone') ||
                            device.label.includes('Headphones') ||
                            device.label.includes('Microphones')
                        )
                        .map((device, index) => (
                          <>
                            <div
                              className={`headphone-item ${
                                activeDevices.audioOut === device.deviceId
                                  ? 'text-primary'
                                  : 'text-white'
                              }`}
                              key={device.deviceId}
                              onClick={(e) => handleAudioOutChange(e, device)}
                            >
                              {device.label}
                              {/* <TiTick
                                                            color='#ED731B'
                                                            size='1rem'
                                                            style={{
                                                                visibility:
                                                                    activeDevices.audioOut === device.deviceId
                                                                        ? 'visible'
                                                                        : 'hidden',
                                                            }}
                                                        /> */}
                              <div
                                style={{
                                  visibility:
                                    activeDevices.audioOut === device.deviceId
                                      ? 'visible'
                                      : 'hidden'
                                }}
                              >
                                <OrangeTick />
                              </div>
                            </div>
                            {/* {index !== outputOptions.media.length - 1 && (
                                                                        <div className='separator'></div>
                                                                    )} */}
                          </>
                        ))}

                    {outputOptions.media.length !== 0 &&
                      deviceMode === DeviceMode.Main &&
                      outputOptions.media.some(
                        (device) =>
                          device.label.includes('Headphone') || device.label.includes('Headphones')
                      ) && <div className="separator" />}
                    {outputOptions.media.length !== 0 && deviceMode === DeviceMode.Main && (
                      <div div className="speaker-item">
                        Select a speaker
                      </div>
                    )}
                    {outputOptions.media.length !== 0 &&
                      deviceMode === DeviceMode.Main &&
                      outputOptions.media
                        .filter(
                          (device) =>
                            !device.label.includes('Headphone') ||
                            device.label.includes('Headphones')
                        )
                        .map((device, index) => (
                          <>
                            <div
                              className={`speaker-item-container ${
                                activeDevices.audioOut === device.deviceId
                                  ? 'text-primary'
                                  : 'text-white'
                              }`}
                              key={device.deviceId}
                              onClick={(e) => handleAudioOutChange(e, device)}
                            >
                              {/* {console.log(device, "|||")} */}

                              {device.label}
                              {/* <TiTick
                                                                             color='#ED731B'
                                                                             size='1rem'
                                                                             style={{
                                                                             visibility:
                                                                             activeDevices.audioOut === device.deviceId
                                                                             ? 'visible'
                                                                              : 'hidden',
                                                                          }}
                                                                        /> */}
                              <div
                                style={{
                                  visibility:
                                    activeDevices.audioOut === device.deviceId
                                      ? 'visible'
                                      : 'hidden'
                                }}
                              >
                                <OrangeTick />
                              </div>
                            </div>
                            {/* {index !== outputOptions.media.length - 1 && (
                                                                     <div className='separator'></div>
                                                                    )} */}
                          </>
                        ))}
                    {camOptions.media.length !== 0 && (
                      <>
                        {outputOptions.media.length !== 0 && deviceMode === DeviceMode.Main && (
                          <div className="separator" />
                        )}
                        <div className="speaker-item">Select a camera</div>
                      </>
                    )}
                    {camOptions.media.map((device, index) => (
                      <>
                        <div
                          className={`speaker-item-container ${
                            activeDevices.video === device.deviceId ? 'text-primary' : 'text-white'
                          }`}
                          key={device.deviceId}
                          onClick={(e) => handleCamChange(e, device)}
                        >
                          {device.label}
                          <div
                            style={{
                              visibility:
                                activeDevices.video === device.deviceId ? 'visible' : 'hidden'
                            }}
                          >
                            <OrangeTick />
                          </div>
                        </div>
                        {/* {index !== outputOptions.media.length - 1 && (
                                                                     <div className='separator'></div>
                                                                    )} */}
                      </>
                    ))}
                    {outputOptions.media.length !== 0 && deviceMode === DeviceMode.Main && (
                      <>
                        <div className="separator" />
                        <div className="speaker-item">Select a microphone</div>
                      </>
                    )}
                    {micOptions.media.length !== 0 &&
                      deviceMode === DeviceMode.Main &&
                      micOptions.media.map((device, index) => (
                        <>
                          <div
                            className={`speaker-item-container ${
                              activeDevices.audio === device.deviceId
                                ? 'text-primary'
                                : 'text-white'
                            }`}
                            key={device.deviceId}
                            onClick={(e) => handleMicChange(e, device)}
                          >
                            {device.label}
                            <div
                              style={{
                                visibility:
                                  activeDevices.audio === device.deviceId ? 'visible' : 'hidden'
                              }}
                            >
                              <OrangeTick />
                            </div>
                          </div>
                          {/* {index !== outputOptions.media.length - 1 && (
                                                                     <div className='separator'></div>
                                                                    )} */}
                        </>
                      ))}
                    {deviceMode === DeviceMode.Main && (
                      <>
                        <div className="separator" />
                        <div
                          className="speaker-item-container text-white font-medium"
                          onClick={handleSettings}
                        >
                          Test speaker and microphone
                        </div>
                      </>
                    )}
                  </div>
                  {noDeviceFound && (
                    <div className="device-item cursor-pointer">No devices found!</div>
                  )}
                </div>
              )}
            </div>

            {/* participants list */}
            {/* <div className='media-control cursor-pointer'>
					<span
						onClick={handleTogglePaticipantPanel}
						data-tooltip-id='video-tooltip'
						data-tooltip-content='View Participants'
					>
						{showParticipantPanel ? (
							<FaUsersSlash
								data-tooltip-id='video-tooltip'
								data-tooltip-content='Hide participants'
								size='1.5rem'
								color='#ED731B'
							/>
						) : (
							<FaUsers
								data-tooltip-id='video-tooltip'
								data-tooltip-content='Show participants'
								size='1.5rem'
								color='#ED731B'
							/>
						)}
					</span>
				</div> */}

            {/* Gallery list (session screenshot) */}
            {/* <div className='media-control cursor-pointer'>
					<span
						onClick={handleToggleGalleryPanel}
						data-tooltip-id='video-tooltip'
						data-tooltip-content='Session Photos'
					>
						<FcGallery size='1.5rem' />
					</span>
				</div>  */}

            {/* settings controls */}
            {/* <div className='media-control cursor-pointer'>
					<span
						onClick={handleSettings}
						data-tooltip-id='video-tooltip'
						data-tooltip-content='Audio/Video Settings'
					>
						<IoMdSettings color='#ED731B' size='1.5rem' />
					</span>
				</div> */}

            {/* lock meeting section */}
            {/* {localParticipantInfo.isAdmin && (
					<div className='media-control cursor-pointer'>
						<div onClick={handleLockMeeting}>
							{isMeetingLocked ? (
								<HiLockClosed
									size='1.5rem'
									color='#ED731B'
									data-tooltip-id='video-tooltip'
									data-tooltip-content='Meeting Locked'
								/>
							) : (
								<HiLockOpen
									size='1.5rem'
									color='#cbcbcb'
									data-tooltip-id='video-tooltip'
									data-tooltip-content='Meeting Unlocked'
								/>
							)}
						</div>
					</div>
				)} */}

            {/* primary & aux device control */}
            {/* <div className='media-control cursor-pointer'>
					<div onClick={toggleDevice}>
						{deviceMode === DeviceMode.Main ? (
							<MdImportantDevices
								size='1.5rem'
								color='#ED731B'
								data-tooltip-id='video-tooltip'
								data-tooltip-content='Main Device'
							/>
						) : (
							<MdDevices
								size='1.5rem'
								color='#ED731B'
								data-tooltip-id='video-tooltip'
								data-tooltip-content='Aux Device'
							/>
						)}
					</div>
				</div> */}

            {/* mute all */}
            {/* {localParticipantInfo.isAdmin && (
                    <div className='media-control cursor-pointer bg-vidgrey py-2 px-11px newMuteAll' onClick={handleParticipantMuteToggleAll}>
                        <span className='device-label cursor-pointer text-white text-15px font-semibold' style={{textShadow: '1px 1px 4px rgba(0, 0, 0, 0.65)'}}>
							{isAllMuted ? 'Ask to unmute' : 'Mute All'}
						</span>
						<span>
							{isAllMuted ? (
								<BsFillMicMuteFill
									size='1.5rem'
									color='#ED731B'
									data-tooltip-id='video-tooltip'
									data-tooltip-content='Class is muted, Ask to unmute'
								/>
							) : (
								// <BsFillMicFill
								// 	size='1.5rem'
								// 	color='#cbcbcb'
								// 	data-tooltip-id='video-tooltip'
								// 	data-tooltip-content='Mute Class'
                                    // />
                                    <div><MuteAllIcon /></div>
							)}
						</span>
					</div>
				)} */}

            {/* play mp3 file */}
            {/* {localParticipantInfo.isAdmin && (
					<div className='media-control cursor-pointer'>
						<span
							onClick={handleAudioFileToggle}
							data-tooltip-id='video-tooltip'
							data-tooltip-content='Play music in class'
						>
							{audioPlayInfo.show ? (
								<BsFillFileMusicFill size='1.5rem' color='#ED731B' />
							) : (
								<BsFillFileMusicFill size='1.5rem' color='#cbcbcb' />
							)}
						</span>
						{audioPlayInfo.show && (
							<div
								className='devices-container'
								style={{
									marginTop: `-10rem`,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: '25rem',
								}}
							>
								<audio
									id={'admin-shared-audio'}
									controls
									style={{ display: audioPlayInfo.file ? 'block' : 'none' }}
								/>

								<div
									style={{
										margin: '1rem',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<label htmlFor='media-file-input' id='music-label'>
										{audioPlayInfo.file ? (
											<BsFillFileEarmarkMusicFill
												className='media-file-selection'
												size='1.5rem'
												color='#ED731B'
												data-tooltip-id='video-tooltip'
												data-tooltip-content='Click to choose another file'
											/>
										) : (
											<BsFillFileEarmarkMusicFill
												className='media-file-selection'
												size='1.5rem'
												color='#cbcbcb'
												data-tooltip-id='video-tooltip'
												data-tooltip-content='Click to choose mp3 file'
											/>
										)}
									</label>
									<input
										type='file'
										id='media-file-input'
										accept='.mp3,audio/*'
										style={{ display: 'none' }}
										onChange={audioFileSelected}
									/>
								</div>
							</div>
						)}
					</div>
				)} */}

            {/* video visibility option */}
            {!localParticipantInfo.isAdmin && videoVisibilityMenuView()}

            {/* end call */}
            <div className="bg-vidred md:w-50px zm:w-46px md:h-50px zm:h-46px flex justify-center items-center rounded-full cursor-pointer">
              <span
                onClick={handleEndCall}
                data-tooltip-id="video-tooltip"
                data-tooltip-content={currentInnerWidth > 1100 ? 'End call' : ''}
              >
                {/* <MdCallEnd color='white' size='1.5rem' /> */}
                <EndCallIcon />
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${
            showParticipantPanel
              ? 'participants-list-pannel-wrapper'
              : 'hide-participants-list-pannel-wrapper'
          }`}
        >
        <div className={`${showParticipantPanel ? 'show-pannel-wrapper' : 'hide-pannel-wrapper '}`}>
          {showParticipantPanel && 
            <ParticipantsListPannel
              onClose={() => setShowParticipantPanel(!showParticipantPanel)}
              participantsList={{
                getParticipant: Array.from(room.remoteParticipants.values()),
                localParticipant: room.localParticipant
              }}
            />
          }
        </div>
        </div>
        <div
          className={`${
            showGalleryPanel
              ? 'participants-list-pannel-wrapper'
              : 'hide-participants-list-pannel-wrapper'
          }`}
          style={showGalleryPanel ? { overflowY: 'scroll', marginBottom: '30px' } : {}}
        >
          {showGalleryPanel ? (
            <SessionImagesDrawer
              mediasInSession={mediasInSession}
              onClose={() => setShowGalleryPanel((val) => !val)}
              handleMediaClick={handleMediaClick}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* all participants panel */}
      {/* <div className={showParticipantPanel ? 'participant-panel show' : 'participant-panel hide'}>
				<h4 style={{ color: '#fff', padding: '1rem' }}>All Participants</h4>
				<div className='participant-card'>
					<span>{capitalizeFirstLetter(room.localParticipant.name)}</span>
					<span style={{ fontSize: '.8rem' }}>(You)</span>
				</div>
				{Array.from(room.remoteParticipants.values()).map((participant) => {
					return (
						<div className='participant-card' key={participant.identity}>
							<span>{capitalizeFirstLetter(participant.name)}</span>
						</div>
					);
				})}
			</div> */}

      {/* all participants panel */}
      {/* <div className={showGalleryPanel ? 'participant-panel show' : 'participant-panel hide'}>
            <h4
                style={{
                    color: '#fff',
                    padding: '1rem',
                    position: 'sticky',
                    top: '-1rem',
                    zIndex: '10',
                    backgroundColor: '#000',
                    width: '100%',
                }}
            >
                All Images
            </h4>
            {mediasInSession ? (
                mediasInSession.map((image, index) => {
                    return (
                        <div key={index} className='participant-card' onClick={() => handleMediaClick(image)}>
                            <img style={{ width: 'auto', height: 'auto' }} src={image.url} />
                        </div>
                    );
                })
            ) : (
                <p>No images were taken in this session yet!</p>
            )}
        </div> */}
      <ClosedCaptions
        text={closedCaptions}
        teacher={meetingArgs.teacher}
        data-tooltip-id="video-tooltip"
        data-tooltip-content="Live transcription of teacher only audio."
      />

      {!isConnectedToRoom && connectingToRoom && <Loader />}
    </>
  );
}

export default React.memo(LivekitVideoContainer);
